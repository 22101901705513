//Language Arabe


export default {
    Pos_Settings:'إعدادات نقاط البيع',
    Receipt:'الإيصال',
    Note_to_customer:'ملاحظة للعميل',
    Show_Note_to_customer:'إظهار ملاحظة للعميل',
    Show_barcode:'إظهار الشفرة',
    Show_Tax_and_Discount:'إظهار الضرائب والخصم والشحن',
    Show_Customer:'إظهار العميل',
    Show_Email:'إظهار البريد الإلكتروني',
    Show_Phone:'إظهار الهاتف',
    Show_Address:'إظهار العنوان',
    DefaultLanguage:'اللغة الافتراضية',
    footer:'تذييل',
    Received_Amount:'المبلغ المستلم',
    Paying_Amount:'مبلغ الدفع',
    Change:'الصرف',
    Paying_amount_is_greater_than_Received_amount:'مبلغ الدفع أكبر من المبلغ المستلم',
    Paying_amount_is_greater_than_Grand_Total:'مبلغ الدفع أكبر من المبلغ الإجمالي الكلي',
    code_must_be_not_exist_already:'يجب أن يكون الرمز غير موجود بالفعل',
    You_will_find_your_backup_on:'سوف تجد النسخة الاحتياطية الخاصة بك على',
    and_save_it_to_your_pc:'و قم بحفظه على جهاز الكمبيوتر الخاص بك',
    Scan_your_barcode_and_select_the_correct_symbology_below:'امسح الرمز الشريطي ضوئيًا وحدد الترميز الصحيح أدناه',
    Scan_Search_Product_by_Code_Name:'مسح / بحث عن المنتج حسب الاسم الرمزي',
    Paper_size:'حجم الورق',
    Clear_Cache:'مسح ذاكرة التخزين المؤقت',
    Cache_cleared_successfully:'تم مسح ذاكرة التخزين المؤقت بنجاح',
    Failed_to_clear_cache:'فشل مسح ذاكرة التخزين المؤقت',
    Scan_Barcode:'ماسح الباركود',
    Please_use_short_name_of_unit:'الرجاء استخدام الاسم المختصر للوحدة',
    DefaultCustomer:'العميل الافتراضي',
    DefaultWarehouse:'المستودع الافتراضي',
    Payment_Gateway:'بوابة الدفع',
    SMS_Configuration:'إعدادات الرسائل القصيرة',
    Gateway:'بوابة الدفع',
    Choose_Gateway:'اختر بوابة الدفع',
    Send_SMS :'تم ارسال الرسالة بنجاح',
    sms_config_invalid:'إعدادات الرسائل القصيرة غير صالحة',
    Remove_Stripe_Key_Secret:'حذف مفاتيح Stripe API',
    credit_card_account_not_available :'حساب بطاقة الائتمان غير متوفر',
    Credit_Card_Info:'معلومات بطاقة الائتمان',
    developed_by:'طورت بواسطة',
    Unit_already_linked_with_sub_unit:'الوحدة مرتبطة بالفعل بوحدة فرعية',
    Total_Items_Quantity : 'مجموع العناصر والكمية',
    Value_by_Cost_and_Price : 'القيمة حسب التكلفة والسعر',
    Search_this_table: 'ابحث في هذا الجدول',
    import_products:'استيراد المنتجات',
    Field_optional:'حقل اختياري',
    Download_exemple:'تحميل المثال',
    field_must_be_in_csv_format:'يجب أن يكون الحقل بتنسيق csv',
    Successfully_Imported:'تم الاستيراد بنجاح',
    file_size_must_be_less_than_1_mega:'يجب أن يكون حجم الملف أقل من 1 ميغا',
    Please_follow_the_import_instructions:'يرجى اتباع تعليمات الاستيراد',
    must_be_exist:'يجب أن تكون الوحدة قد تم إنشاؤها بالفعل',
    Import_Customers:'استيراد العملاء',
    Import_Suppliers:'استيراد الموردين',
    Recent_Sales : 'المبيعات الأخيرة',
    Create_Transfer : 'إنشاء تحويل',
    order_products : 'طلب المنتجات',
    Search_Product_by_Code_Name : 'ابحث عن المنتج بالكود أو الاسم',
    Reports_payments_Purchase_Return:'تقارير مدفوعات مرتجعات الشراء',
    Reports_payments_Sale_Return:'تقارير مدفوعات مرتجعات البيع',
    payments_Sales_Return:'مدفوعات إرجاع المبيعات',
    payments_Purchases_Return:'مدفوعات مشتريات العودة',
    CreateSaleReturn:'إنشاء عائد بيع',
    EditSaleReturn:'تحرير إرجاع البيع',
    SalesReturn:'عائد المبيعات',
    CreatePurchaseReturn:'إنشاء إرجاع الشراء',
    EditPurchaseReturn:'تحرير إرجاع الشراء',
    PurchasesReturn:'عائد المشتريات',
    Due:'دين',
    Profit: 'ربح',
    Revenue: 'دخل',
    Sales_today: 'مبيعات اليوم',
    People: 'اشخاص',
    Successfully_Created: 'تم إنشاؤه بنجاح',
    Successfully_Updated: 'تم التحديث بنجاح',
    Success: 'نجاح',
    Failed: 'باءت بالفشل',
    Warning: 'إنذار',
    Please_fill_the_form_correctly: 'يرجى ملء النموذج بشكل صحيح',
    Field_is_required: 'هذا الحقل مطلوب',
    Choose_Status: 'اختر الحالة',
    Choose_Method: 'اختر الطريقة',
    Choose_Symbology: 'اختر الترميز',
    Choose_Category: 'اختر الفئة',
    Choose_Customer: 'اختر الزبون',
    Choose_Supplier: 'اختر المورد',
    Choose_Unit_Purchase: 'اختر وحدة الشراء',
    Choose_Sub_Category: 'اختر الفئة الفرعية',
    Choose_Brand: 'اختر الماركة',
    Choose_Warehouse: 'اختر المستودع',
    Choose_Unit_Sale: 'اختر وحدة البيع',
    Enter_Product_Cost: 'أدخل تكلفة المنتج',
    Enter_Stock_alert: 'أدخل تنبيه المخزون',
    Enter_Quantity: 'أدخل الكمية',
    Warehouses_Master: 'مخازن رئيسية',
    Choose_Unit_Product: 'اختر وحدة المنتج',
    Enter_Product_Price: 'أدخل سعر المنتج',
    Enter_Name_Product: 'أدخل اسم المنتج',
    Enter_Role_Name: 'أدخل اسم الدور',
    Enter_Role_Description: 'أدخل وصف الدور',
    Enter_name_category: 'أدخل اسم الفئة',
    Enter_Code_category: 'أدخل رمز الفئة',
    Enter_Name_Brand: 'أدخل اسم الماركة',
    Enter_Description_Brand: 'أدخل وصف الماركة ',
    Enter_Code_Currency: 'أدخل رمز العملة',
    Enter_name_Currency: 'أدخل اسم العملة',
    Enter_Symbol_Currency: 'أدخل رمز العملة',
    Enter_Name_Unit: 'أدخل اسم وحدة',
    Enter_ShortName_Unit: 'أدخل اسمًا قصيرًا للوحدة',
    Choose_Base_Unit: 'اختر وحدة القاعدة',
    Choose_Operator: 'اختر عامل التشغيل',
    Enter_Operation_Value: 'أدخل قيمة العملية',
    Enter_Name_Warehouse: 'أدخل اسم المستودع',
    Enter_Phone_Warehouse: 'أدخل هاتف المستودع',
    Enter_Country_Warehouse: 'أدخل بلد المستودع',
    Enter_City_Warehouse: 'أدخل مدينة المستودع',
    Enter_Email_Warehouse: 'أدخل البريد الإلكتروني للمستودع',
    Enter_ZipCode_Warehouse: 'أدخل الرمز البريدي للمستودع',
    Choose_Currency: 'اختر العملة',
    Thank_you_for_your_business: 'شكرا لك على عملك !',
    Cancel: 'إلغاء',
    New_Customer: 'عميل جديد',
    Incorrect_Login: 'تسجيل الدخول غير صحيح',
    Successfully_Logged_In: 'تم تسجيل الدخول بنجاح',
    This_user_not_active: 'هذا المستخدم غير نشط',
    SignIn: 'تسجيل الدخول',
    Create_an_account: 'انشئ حساب',
    Forgot_Password: 'هل نسيت كلمة السر',
    Email_Address: 'بريد الكتروني',
    SignUp: 'اشتراك',
    Already_have_an_account: 'هل لديك حساب ؟',
    Reset_Password: 'إعادة تعيين كلمة المرور',
    Failed_to_authenticate_on_SMTP_server: 'فشل المصادقة على خادم SMTP',
    We_cant_find_a_user_with_that_email_addres: 'لا يمكننا العثور على مستخدم بعنوان البريد الإلكتروني هذا',
    We_have_emailed_your_password_reset_link: 'لقد أرسلنا رابط إعادة تعيين كلمة المرور بالبريد الإلكتروني',
    Please_fill_the_Email_Adress: 'يرجى ملء عنوان البريد الإلكتروني',
    Confirm_password: 'تأكيد كلمة المرور',
    Your_Password_has_been_changed: 'تم تغيير كلمة السر الخاصة بك',
    The_password_confirmation_does_not_match: 'تأكيد كلمة المرور غير متطابق',
    This_password_reset_token_is_invalid: 'رمز إعادة تعيين كلمة المرور هذا غير صالح',
    Warehouse_report: 'تقرير المستودع',
    All_Warehouses: 'جميع المستودعات',
    Expense_List: 'قائمة المصاريف',
    Expenses: 'المصاريف',
    This_Week_Sales_Purchases: 'مبيعات ومشتريات هذا الأسبوع',
    Top_Selling_Products: 'المنتجات الأكثر مبيعًا',
    View_all: 'مشاهدة الكل',
    Payment_Sent_Received: 'الدفع - المرسل والمستلم',
    Error: 'خطأ !',
    you_are_not_authorized: 'آسف! أنك غير مخول.',
    Go_back_to_home: 'العودة الى الصفحة الرئيسية',
    page_not_exist: 'آسف! الصفحة التي تبحث عنها غير موجودة.',
    Filter: 'فلتر',
    Invoice_POS: 'فاتورة نقاط البيع',
    Invoice: 'فاتورة',
    Customer_Info: 'معلومات العميل',
    Company_Info: 'معلومات الشركة',
    Invoice_Info: 'معلومات الفاتورة',
    Order_Summary: 'ملخص الطلب',
    Quote_Info: 'معلومات الاقتباس',
    Del: 'حذف',
    SuppliersPaiementsReport: 'تقرير مدفوعات الموردين',
    Purchase_Info: 'معلومات الشراء',
    Supplier_Info: 'معلومات المورد',
    Return_Info: 'معلومات العودة',
    Expense_Category: 'فئة المصاريف',
    Create_Expense: 'أضف المصاريف',
    Details: 'تفاصيل',
    Discount_Method: 'طريقة الخصم',
    Net_Unit_Cost: 'صافي تكلفة الوحدة',
    Net_Unit_Price: 'صافي سعر الوحدة',
    Edit_Expense: 'تحرير المصاريف',
    All_Brand: 'جميع العلامات التجارية',
    All_Category: 'كل الفئات',
    ListExpenses: 'قائمة المصروفات',
    Create_Permission: 'إنشاء إذن',
    Edit_Permission: 'تحرير إذن',
    Reports_payments_Sales: 'تقارير مدفوعات المبيعات',
    Reports_payments_Purchases: 'تقارير مشتريات المدفوعات',
    Reports_payments_Return_Customers: 'تقارير المدفوعات المرتجعة للعملاء',
    Reports_payments_Return_Suppliers: 'تقارير المدفوعات عودة الموردين',
    Expense_Deleted: 'تم حذف هذه المصاريف',
    Expense_Updated: 'تم تحديث هذه المصاريف',
    Expense_Created: 'تم إنشاء هذه المصاريف',
    DemoVersion: 'لا يمكنك القيام بذلك في النسخة التجريبية',
    Filesize: 'حجم الملف',
    GenerateBackup: 'إنشاء نسخة احتياطية',
    BackupDatabase: 'النسخ الاحتياطية',
    Backup: 'النسخ الاحتياطية',
    OrderStatistics: 'إحصائيات المبيعات',
    AlreadyAdd: 'هذا المنتج مضاف بالفعل',
    AddProductToList: 'الرجاء إضافة المنتج إلى القائمة',
    AddQuantity: 'الرجاء إضافة الكمية للمنتج',
    InvalidData: 'بيانات غير صالحة',
    LowStock: 'الكمية تتجاوز الكمية المتوفرة في المخزون',
    WarehouseIdentical: 'لا يمكن أن يكون المستودعين متطابقين',
    VariantDuplicate: 'هذا المتغير مكرر',
    Paid: 'مدفوعة',
    Unpaid: 'غير مدفوعة',
    IncomeExpenses: 'الدخل مقابل المصروفات',
    dailySalesPurchases: 'المبيعات والمشتريات اليومية',
    ProductsExpired: 'المنتجات منتهية الصلاحية',
    Income: 'الدخل',
    Expenses: 'المصروفات',
    Sale: 'المبيعة',
    Actif: 'فعال',
    Inactif: 'غير فعال',
    Phone: 'الهاتف',
    SearchByPhone: 'البحت بالهاتف',
    CustomerName: 'اسم العميل',
    StockManagement: 'إدارة المخزون',
    dashboard: 'لوحة الإستعلام',
    Products: 'منتجات',
    productsList: 'قائمة المنتجات',
    StockTransfers: 'تحويل المخزون',
    Customers: 'الزبائن',
    Suppliers: 'الموردون',
    Quotations: 'عرض الأسعار',
    Sales: 'المبيعات',
    Purchases: 'المشتريات',
    Returns: 'العوائد',
    Settings: 'الإعدادات',
    SystemSettings: 'إعدادات النظام',
    Users: 'المستخدمين',
    GroupPermissions: 'صلاحية المجموعة',
    Currencies: 'العملات',
    ProductTax: 'ضريبة المنتج',
    Categories: 'التصنيفات',
    Warehouses: 'المستودعات',
    Units: 'الوحدات',
    UnitsPrchases: 'وحدات الشراء',
    UnitsSales: 'وحدات البيع',
    Reports: 'التقارير',
    PaymentsReport: 'تقرير المدفوعات',
    PaymentsPurchases: 'مدفوعات المشتريات',
    PaymentsSales: 'مدفوعات المبيعات',
    PaymentsReturns: 'مدفوعات المرتجعات',
    ProfitandLoss: 'الربح والخسارة',
    ProductQuantityAlerts: 'تنبيهات كمية المنتج',
    WarehouseStockChart: 'الرسم البياني لسهم المستودع',
    SalesReport: 'تقرير المبيعات',
    PurchasesReport: 'تقرير المشتريات',
    CustomersReport: 'تقرير العملاء',
    SuppliersReport: 'تقرير الموردين',
    SupplierReport: 'تقرير المورد',
    DailySalesData: 'بيانات المبيعات اليومية',
    DailyPurchasesData: 'بيانات المشتريات اليومية',
    Dernièrescinqrecords: 'السجلات الخمسة الأخيرة',
    Filters: 'البحت',
    date: 'التاريخ',
    Reference: 'المرجع',
    Supplier: 'المورد',
    PaymentStatus: 'حالة الدفع',
    Customer: 'الزبون',
    CustomerCode: 'رمز الزبون',
    Status: 'الحالة',
    SupplierCode: 'رمز المورد',
    //المنتج
    ProductManagement: 'إدارة المنتجات',
    CodeProduct: 'رمز المنتج',
    Categorie: 'التصنيف',
    SubCategorie: 'التصنيف الفرعي',
    Name_product: 'إسم المنتج',
    StockAlert: 'تنبيه المخزون',
    warehouse: 'المستودع',
    Tax: 'الضريبة',
    BuyingPrice: 'سعر الشراء',
    SellPrice: 'سعر البيع',
    Quantity: 'كمية',
    Action: 'الإجراءات',
    UnitSale: 'وحدة البيع',
    UnitPurchase: 'وحدة الشراء',
    ProductDetails: 'تفاصيل المنتج',
    //filters
    All: 'الكل',
    EditProduct: 'تعديل المنتج',
    AddProduct: 'إضافة منتج',
    SearchByCode: 'البحت بالرمز',
    SearchByName: 'البحت بالاسم',
    Edit: 'تعديل',
    Close: 'أغلق',
    PleaseSelect: 'يرجى التحديد',
    CustomerManagement: 'إدارة الزبائن',
    Email: 'البريد ',
    EditCustomer: 'تحرير العميل',
    AddCustomer: 'إضافة عميل',
    Country: 'البلد',
    City: 'المدينة',
    Adress: 'العنوان',
    CustomerDetails: 'تفاصيل العميل',
    CustomersList: 'قائمة الزبائن',
    SupplierCode: 'رمز المورد',
    SupplierName: 'اسم المورد',
    SuppliersManagement: 'إدارة الموردين',
    SupplierDetails: 'تفاصيل المورد',
    QuotationsManagement: 'إدارة التسعيرات',
    SubTotal: 'المجموع الكلي',
    complete: 'مكتملة',
    EnAttendant: 'قيد الانتظار',
    Recu: 'تم الاستلام',
    partial: 'جزئي',
    Retournee: 'مسترجعة',
    DetailQuote: 'تفاصيل عرض السعر ',
    EditQuote: 'تعديل عرض السعر',
    CreateSale: 'إنشاء مبيعة',
    DownloadPdf: 'تحميل PDF',
    QuoteEmail: 'ارسال عرض السعر بالبريد الالكتروني',
    DeleteQuote: 'حذف عرض السعر',
    AddQuote: 'إضافة عرض سعر',
    SelectProduct: 'حدد المنتج',
    Price: 'السعر',
    CurrentStock: 'المخزون ',
    Total: 'مجموع',
    Num: 'رقم',
    Unitcost: 'تكلفة الوحدة',
    to: 'إلى',
    Subject: 'الموضوع',
    Message: 'الرسالة',
    EmailCustomer: 'البريد الإلكتروني للعميل',
    Sent: 'إرسال',
    ProductCodeName: 'المنتج',
    Quote: 'عرض الأسعار',
    Hello: 'أهلا',
    AttachmentQuote: 'يرجى العثور على المرفق لعرض الأسعار الخاص بك',
    AddProducts: 'إضافة منتجات إلى قائمة الطلبات',
    SelectWarehouse: 'الرجاء اختيار المستودع',
    SelectCustomer: 'اختر الزبون',
    SalesManagement: 'إدارة المبيعات',
    Balance: 'الرصيد',
    QtyBack: 'كمية العوائد',
    TotalReturn: 'مجموع العوائد',
    MontantReste: 'المبلغ المتبقي',
    SaleDetail: 'تفاصيل المبيعة',
    EditSale: 'تعديل المبيعة',
    AddSale: 'إضافة مبيعة',
    ShowPayment: 'إظهار المدفوعات',
    AddPayment: 'إضافة الدفع',
    EditPayment: 'تعديل الدفع',
    EmailSale: 'إرسال المبيعة في البريد الإلكتروني',
    DeleteSale: 'حذف المبيعة',
    Amount: 'المبلغ',
    ModePaiement: 'طريقة الدفع',
    Paymentchoice: 'طريقة الدفع',
    Note: 'ملحوظة',
    PaymentComplete: 'اكتمل الدفع',
    UserManagement: 'إدارة المستخدمين',
    Firstname: 'الاسم الأول',
    lastname: 'اسم العائلة',
    username: 'اسم المستخدم',
    type: 'النوع',
    UserPermissions: 'تراخيص المستخدمين',
    RoleName: 'اسم الدور',
    RoleDescription: 'وصف الدور',
    AddPermissions: 'اضافة التراخيص',
    View: 'عرض',
    Add: 'إضافة',
    add: 'إضافة',
    Del: 'حذف',
    NewAdjustement: 'تعديل جديد',
    EditAdjustement: 'تحرير التعديل',
    CannotSubstraction: 'لا يمكنك طرح منتجات لها مخزون 0',
    Addition: 'إضافة',
    Subtraction: 'طرح',
    profil: 'الملف الشخصي',
    logout: 'تسجيل الخروج',
    PurchaseAlreadyPaid: 'لا يمكنك التعديل لأن هذا الشراء  مكتمل الدفع  بالفع',
    SaleAlreadyPaid: 'لا يمكنك التعديل لأن هذه المبيعة  مكتملة الدفع  بالفع',
    ReturnAlreadyPaid: 'لا يمكنك التعديل لأن هذه المرتجعة  مكتملة الدفع  بالفع',
    QuoteAlready: ' هذه التسعيرة أدت بالفعل إلى بيع',
    QuotationComplete: 'هده التسعيرة مكتملة',
    password: 'كلمة المرور',
    Newpassword: 'كلمة المرور جديدة',
    ChangeAvatar: 'تغيير الصورة',
    LeaveBlank: 'يرجى ترك هذا الحقل فارغًا إذا لم تقم بتغييره',
    SiteConfiguration: 'اعدادات عامة',
    Language: 'اللغة',
    DefaultCurrency: 'العملة الرئيسية',
    LoginCaptcha: 'كلمة التحقق',
    DefaultEmail: 'البريد الرئيسي',
    SiteName: 'اسم الموقع',
    ChangeLogo: 'تغيير الشعار',
    SMTPConfiguration: 'اعدادات الارسال',
    HOST: 'مضيف',
    PORT: 'المنفذ',
    encryption: 'التشفير',
    SMTPIncorrect: 'اعدادات الارسال غير صحيحة',
    ReturnsInvoices: 'فواتير المرتجعات',
    ReturnsInvoicesData: 'بيانات فواتير المرتجعات',
    ShowAll: 'إظهار كافة سجلات كافة المستخدمين',
    Discount: 'الخصم',
    OrderTax: 'ضريبة الطلب',
    Shipping: 'الشحن',
    ManagementCurrencies: 'ادارة العملات',
    CurrencyCode: 'شفرة العملة',
    CurrencyName: 'اسم العملة',
    Symbol: 'رمز العملة',
    CompanyName: 'اسم الشركة',
    CompanyPhone: 'هاتف الشركة',
    CompanyAdress: 'عنوان الشركة',
    Code: 'رمز',
    image: 'صورة',
    Printbarcode: 'طباعة الباركود',
    ReturnsCustomers: 'مرتجعات العملاء',
    ReturnsSuppliers: 'مرتجعات الموردين',
    FactureReturnCustomers: 'فواتير مرتجعات العملاء',
    FactureReturnSuppliers: 'فواتير مرتجعات الموردين',
    NodataAvailable: 'لا تتوافر بيانات',
    ProductImage: 'صورة المنتج',
    Barcode: 'الباركود',
    pointofsales: 'نقطة مبيعات',
    CustomUpload: 'تحميل مخصص',
    pointofsaleManagement: 'إدارة نقاط البيع',
    Adjustment: 'تعديل',
    Updat: 'تحديث',
    Reset: 'إعادة تعيين',
    print: 'طباعة',
    SearchByEmail: 'البحث بالبريد الإلكتروني',
    ChooseProduct: 'اختر المنتج',
    Qty: 'الكمية',
    Items: 'العناصر',
    AmountHT: 'المبلغ دون ضريبة',
    AmountTTC: 'المبلغ الإجمالي',
    PleaseSelectSupplier: 'الرجاء تحديد المورد',
    PleaseSelectStatut: 'يرجى تحديد الحالة',
    PayeBy: 'الدفع عن طريق',
    ChooseWarehouse: 'اختر المستودع',
    payNow: 'ادفع الآن',
    ListofCategory: 'قائمة الفئات',
    Description: 'وصف',
    submit: 'تأكيد',
    ProblemCreatingThisInvoice: 'حدثت مشكلة في إنشاء هذه الفاتورة. حاول مرة اخرى',
    ProblemPayment: 'كانت هناك مشكلة في الدفع. حاول مرة اخرى',
    CreateAdjustment: 'إنشاء تعديل',
    Afewwords: '... بضع كلمات عن',
    UserImage: 'صورة المستخدم',
    UpdateProduct: 'تحديث المنتج',
    Brand: 'الماركة',
    BarcodeSymbology: 'ترميز الباركود',
    ProductCost: 'تكلفة المنتج',
    ProductPrice: 'سعر المنتج',
    UnitProduct: 'منتج الوحدة',
    TaxMethod: 'الطريقة الضريبية',
    MultipleImage: 'صور متعددة',
    ProductHasMultiVariants: 'المنتج له متغيرات متعددة',
    ProductHasPromotion: 'المنتج له عرض ترويجي',
    PromotionStart: 'بدء الترويج',
    PromotionEnd: 'نهاية الترويج',
    PromotionPrice: 'سعر الترويج',
    Price: 'السعر',
    Cost: 'كلفة',
    Unit: 'وحدة',
    ProductVariant: 'متغير المنتج',
    Variant: 'متغير',
    UnitPrice: 'سعر الوحدة',
    CreateReturnCustomer: 'إنشاء إرجاع العميل',
    EditReturnCustomer: 'تحرير إرجاع العميل',
    CreateReturnSupplier: 'إنشاء إرجاع المورد',
    Documentation: 'توثيق',
    EditReturnSupplier: 'تحرير إرجاع المورد',
    FromWarehouse: 'من المستودع',
    ToWarehouse: 'إلى المستودع',
    EditTransfer: 'تحرير النقل',
    TransferDetail: 'تفاصيل النقل',
    Pending: 'قيد الانتظار',
    Received: 'تم الاستلام',
    Ordered: 'طلب',
    PermissionsManager: 'مدير الأذونات',
    BrandManager: 'العلامة التجارية',
    BrandImage: 'صورة الماركة',
    BrandName: 'اسم الماركة',
    BrandDescription: 'ةصف الماركة',
    BaseUnit: 'الوحده الأساسيه',
    ManagerUnits: 'إدارة الوحدات',
    OperationValue: 'قيمة العملية',
    Operator: 'عامل',
    Top5Products: 'أفضل 5 منتجات',
    Last5Sales: 'آخر 5 مبيعات',
    ListAdjustments: 'قائمة التعديلات',
    ListTransfers: 'قائمة التحويلات',
    CreateTransfer: 'إنشاء تحويل',
    OrdersManager: 'مدير الطلبات',
    ListQuotations: 'قائمة التسعيرات',
    ListPurchases: 'قائمة المشتريات',
    ListSales: 'قائمة المبيعات',
    ListReturns: 'قائمة المرتجعات',
    PeopleManager: 'إدارة الأفراد',
    ListofBrand: 'قائمة العلامة التجارية',


    //sweet Alert
    Delete: {
        Title: 'هل أنت واثق؟',
        Text: ' !لن تتمكن من التراجع عن هذا',
        confirmButtonText: 'نعم ، احذفه!',
        cancelButtonText: 'إلغاء',
        Deleted: 'تم الحذف!',
        Failed: 'فشل',
        Therewassomethingwronge: 'كان هناك شيء خاطئ',
        CustomerDeleted: 'تم حذف هذا العميل',
        UserDeleted: 'تم حذف هذا المستخدم',
        SupplierDeleted: 'تم حذف هذا العميل المورد',
        QuoteDeleted: 'تم حذف هذه التسعيرة',
        SaleDeleted: 'تم حذف هذا البيع',
        PaymentDeleted: 'تم حذف هذا الدفع',
        PurchaseDeleted: 'تم حذف هذا الشراء',
        ReturnDeleted: 'تم حذف هذه المرتجعة',
        ProductDeleted: 'تم حذف هدا المنتوج',
        ClientError: 'هذا العميل مرتبط بالفعل بعملية أخرى',
        ProviderError: 'هذا المورد مرتبط بالفعل بعملية أخرى',
        UnitDeleted: 'تم حذف هذه الوحدة',
        RoleDeleted: 'تم حذف هدا الدور',
        TaxeDeleted: 'تم حذف هذه الضريبة بنجاح',
        SubCatDeleted: 'تم حذف هذا  التصنيف فرعي بنجاح',
        CatDeleted: 'تم حذف هذا التصنيف  بنجاح',
        WarehouseDeleted: 'تم حذف هذا المخزن  بنجاح',
        AlreadyLinked: 'هذا المنتج مرتبط بالفعل بعملية أخرى',
        AdjustDeleted: 'تم حذف هذا التعديل  بنجاح',
        TitleCurrency: 'تم حذف هذه العملة  بنجاح',
        TitleTransfer: 'تم حذف النقل  بنجاح',
        BackupDeleted: 'تمت إزالة النسخة الاحتياطية بنجاح',
        TitleBrand: 'تم حذف هذه العلامة التجارية',

    },
    Update: {
        TitleBrand: 'تم تحديث هذه العلامة التجارية',
        TitleSetting: 'تم تحديث الاعدادات بنجاح',
        TitleProfile: 'تم تحديث ملفك الشخصي بنجاح',
        TitleAdjust: 'تم تحديث التعديل بنجاح',
        TitleRole: 'تم تحديث الدور بنجاح',
        TitleUnit: 'تم تحديث الوحدة بنجاح',
        TitleCustomer: 'تم تحديث المستخدم بنجاح',
        TitleCustomer: 'تم تحديث المستخدم بنجاح',
        TitleCustomer: 'تم تحديث العميل بنجاح',
        TitleQuote: 'تم تحديث التسعيرة بنجاح',
        TitleSale: 'تم تحديث البيع بنجاح',
        TitlePayment: 'تم تحديث الدفع بنجاح',
        TitlePurchase: 'تم تحديث الشراء بنجاح',
        TitleReturn: 'تم تحديث المرتجعة بنجاح',
        TitleProduct: 'تم تحديث المنتوج بنجاح',
        TitleSupplier: 'تم تحديث المورد بنجاح',
        TitleTaxe: 'تم تحديث الضريبة بنجاح',
        TitleCat: 'تم تحديث التصنيف بنجاح',
        TitleWarhouse: 'تم تحديث المخزن بنجاح',
        TitleCurrency: 'تم تحديث هذه العملة  بنجاح',
        TitleTransfer: 'تم تحديث النقل  بنجاح',
    },
    Create: {
        TitleBrand: 'تم إنشاء هذه العلامة التجارية',
        TitleTransfer: 'تم إنشاء النقل بنجاح',
        TitleAdjust: 'تم إنشاء التعديل بنجاح',
        TitleRole: 'تم إنشاء الدور بنجاح',
        TitleUnit: 'تم إنشاء الوحدة بنجاح',
        TitleCustomer: 'تم إنشاء المستخدم بنجاح',
        TitleCustomer: 'تم إنشاء العميل بنجاح',
        TitleQuote: 'تم إنشاء التسعيرة بنجاح',
        TitleSale: 'تم إنشاء البيع بنجاح',
        TitlePayment: 'تم إنشاء الدفع بنجاح',
        TitlePurchase: 'تم إنشاء الشراء بنجاح',
        TitleReturn: 'تم إنشاء المرتجعة بنجاح',
        TitleProduct: 'تم إنشاء المنتوج بنجاح',
        TitleSupplier: 'تم إنشاء المورد بنجاح',
        TitleTaxe: 'تم إنشاء الضريبة بنجاح',
        TitleCat: 'تم إنشاء التصنيف بنجاح',
        TitleWarhouse: 'تم إنشاء المخزن بنجاح',
        TitleCurrency: 'تم إنشاء هذه العملة  بنجاح',
    },
    Send: {
        TitleEmail: 'تم الارسال بنجاح',
    },
    return: {
        TitleSale: 'هذا البيع مرتبط بالفعل بإرجاع',
    },
    PurchasesManagement: 'إدارة المشتريات',
    Ordered: 'أمر',
    DeletePurchase: 'حذف الشراء',
    EmailPurchase: 'إرسال الشراء في البريد الإلكتروني',
    EditPurchase: 'تحرير الشراء',
    PurchaseDetail: 'تفاصيل الشراء',
    AddPurchase: 'أضف شراء',
    EmailSupplier: 'البريد الإلكتروني للمورد',
    ReturnManagement: 'إدارة المرتجعات',
    ReturnDetail: 'تفاصيل  المرتجعة',
    EditReturn: 'تحرير المرتجعة',
    AddReturn: 'إضافة مرتجعة',
    EmailReturn: 'إرسال المرتجعة في البريد الإلكتروني',
    DeleteReturn: 'حذف  المرتجعة',
    Retoursurcharge: 'تكلفة إضافية',
    Laivrison: 'التسليم',
    SelectSale: 'حدد المبيعة',
    ZeroPardefault: 'يمكنك حذف العنصر أو تعيين الكمية التي تم إرجاعها إلى الصفر إذا لم يتم إرجاعها',
    Return: 'مرتجعة',
    Purchase: 'شراء',
    PurchaseInvoice: 'مدفوعات المشتريات',
    PurchasesInvoicesData: 'بيانات مدفوعات المشتريات',
    SalesInvoice: 'مدفوعات المبيعات',
    SalesInvoicesData: 'بيانات مدفوعات المبيعات',
    TotalSales: 'إجمالي المبيعات',
    TotalPurchases: 'إجمالي المشتريات',
    TotalReturns: 'إجمالي العائدات',
    PaiementsNet: 'صافي المدفوعات',
    PaiementsSent: 'الدفعات المرسلة',
    PaiementsReceived: 'الدفعات المستلمة',
    Recieved: 'المستلمة',
    Sent: 'المرسلة',
    ProductQuantityAlerts: 'تنبيهات كمية المنتج',
    ProductCode: 'كود المنتج',
    ProductName: 'المنتج',
    AlertQuantity: 'كمية التنبيه',
    WarehouseStockChart: 'مخطط المخزون  المستودعات',
    TotalProducts: 'إجمالي المنتجات',
    TotalQuantity: 'إجمالي الكمية',
    TopCustomers: 'أفضل 5 عملاء',
    TotalAmount: 'المبلغ الإجمالي',
    TotalPaid: 'المبلغ المدفوع',

    CustomerSalesReport: 'تقرير المبيعات ',
    CustomerPaiementsReport: ' تقرير المدفوعات ',
    CustomerQuotationsReport: 'تقرير عروض أسعار ',
    Payments: 'المدفوعات',
    TopSuppliers: 'أفضل 5 الموردين',
    SupplierPurchasesReport: 'تقرير المشتريات ',
    SupplierPaiementsReport: 'تقرير المدفوعات ',
    Name: 'اسم',
    Code: 'الكود',
    ManagementWarehouse: 'إدارة المستودعات',
    ZipCode: 'الرمز البريدي',
    managementCategories: 'إدارة التصنيفات',
    Codecategorie: 'رمز الفئة',
    Namecategorie: 'اسم الفئة',
    Parentcategorie: 'اصل الفئة',
    managementTax: 'إدارة الضرائب',
    TaxName: 'اسم الضريبة',
    TaxRate: 'معدل الضريبة',
    managementUnitPurchases: 'إدارة وحدة المشتريات',
    managementUnitSales: 'إدارة وحدة المبيعات',
    ShortName: 'اسم قصير',
    PleaseSelectThesebeforeaddinganyproduct: 'يرجى تحديد هذه قبل إضافة أي منتج',
    StockAdjustement: 'تعديل المخزون',
    PleaseSelectWarehouse: 'يرجى تحديد المستودع قبل اختيار أي منتج',
    StockTransfer: 'تحويل المخزون',
    Today: 'اليوم',
    SelectPeriod: 'حدد الفترة',
    ThisToday: 'هذا اليوم',
    ThisYear: 'هذا العام',
    ThisMonth: 'هذا الشهر',
    ThisWeek: 'هذا الاسبوع',
    AdjustmentDetail: 'تفاصيل التعديل',
    ActivateUser: 'تم تنشيط هذا المستخدم',
    DisActivateUser: 'تم إلغاء تنشيط هذا المستخدم',
    NotFound: 'الصفحة غير موجودة.',
    oops: 'خطأ! الصفحة غير موجودة.',
    couldNotFind: 'لم نتمكن من العثور على الصفحة التي تبحث عنها ، وفي الوقت نفسه ، يمكنك ذلك',
    ReturnDashboard: 'العودة إلى لوحة القيادة',

     //hrm module
     hrm:'إدارة الموارد البشرية',
     Employees:'الموظفين',
     Attendance:'الحضور',
     Leave_request:'طلب إجازة',
     Leave_type:'نوع الإجازة',
     Company:'الشركة',
     Departments:'الإدارات',
     Designations:'التعيينات',
     Office_Shift:'مكتب التحول',
     Holidays:'العطل',
     Enter_Company_Name:'ادخل اسم الشركة',
     Enter_email_address:'أدخل عنوان البريد الالكتروني',
     Enter_Company_Phone:'أدخل رقم هاتف الشركة',
     Enter_Company_Country:'أدخل بلد الشركة',
     Created_in_successfully:'تم إنشاؤه بنجاح',
     Updated_in_successfully:'تم التحديث بنجاح',
     Deleted_in_successfully:'تم الحذف بنجاح',
     department:'إدارة',
     Enter_Department_Name:'أدخل اسم الإدارة',
     Choose_Company:'اختر الشركة',
     Department_Head:'رئيس القسم',
     Choose_Department_Head:'اختر رئيس القسم',
     Enter_Shift_name:'أدخل اسم التحول',
     Monday_In:'الاثنين من',
     Monday_Out:'الاثنين الى',
     Tuesday_In:'الثلاثاء من',
     tuesday_out:'الثلاثاء الى',
     wednesday_in:'الأربعاء من',
     wednesday_out:'الأربعاء الى',
     thursday_in:'الخميس الى',
     thursday_out:'الخميس الى',
     friday_in:'الجمعة من',
     friday_out:'الجمعة الى',
     saturday_in:'السبت من',
     saturday_out:'السبت الى',
     sunday_in:'الأحد من',
     sunday_out:'الأحد الى',
     Holiday:'الاجازة',
     Enter_title:'أدخل العنوان',
     title:'العنوان',
     start_date:'تاريخ البداية',
     Enter_Start_date:'أدخل تاريخ البدء',
     Finish_Date:'تاريخ الانتهاء',
     Enter_Finish_date:'أدخل تاريخ الانتهاء',
     Please_provide_any_details:'يرجى تقديم أي تفاصيل',
     Attendances:'الحضور',
     Enter_Attendance_date:'أدخل تاريخ الحضور',
     Time_In:'وقت الدخول',
     Time_Out:'وقت الخروج',
     Choose_Employee:'اختر الموظف',
     Employee:'الموظف',
     Work_Duration:'مدة العمل',
     remaining_leaves_are_insufficient:'الإجازات المتبقية غير كافية',
     Leave_Type:'نوع الإجازة',
     Days:'الأيام',
     Department:'إدارة',
     Choose_leave_type:'اختر نوع الإجازة',
     Choose_status:'اختر الحالة',
     Leave_Reason:'سبب الإجازة',
     Enter_Reason_Leave:'أدخل سبب الإجازة',
     Add_Employee:'اضافة موظف',
     FirstName:'الاسم الأول',
     Enter_FirstName:'أدخل الاسم الأول',
     LastName:'الاسم الاخير',
     Enter_LastName:'أدخل الاسم الأخير',
     Gender:'الجنس',
     Choose_Gender:'اختر الجنس',
     Enter_Birth_date:'أدخل تاريخ الميلاد',
     Birth_date:'تاريخ الولادة',
     Enter_Country:'أدخل الدولة',
     Enter_Phone_Number:'أدخل رقم الهاتف',
     joining_date:'تاريخ الانضمام',
     Enter_joining_date:'أدخل تاريخ الانضمام',
     Choose_Designation:'اختر التعيين',
     Designation:'التعيين',
     Office_Shift:'مكتب التحول',
     Choose_Office_Shift:'اختر مكتب التحول',
     Enter_Leaving_Date:'أدخل تاريخ المغادرة',
     Leaving_Date:'تاريخ المغادرة',
     Annual_Leave:'الإجازة السنوية',
     Enter_Annual_Leave:'أدخل الإجازة السنوية',
     Remaining_leave:'الاجازة المتبقية',
     Employee_Details:'تفاصيل الموظف',
     Basic_Information:'معلومات اساسية',
     Family_status:'الوضع العائلي',
     Choose_Family_status:'اختر الحالة العائلية',
     Employment_type:'نوع الوظيفة',
     Select_Employment_type:'حدد نوع التوظيف',
     Enter_City:'أدخل المدينة',
     Province:'مقاطعة',
     Enter_Province:'أدخل المقاطعة',
     Enter_Address:'أدخل العنوان',
     Enter_Zip_code:'أدخل الرمز البريدي',
     Zip_code:'الرمز البريدي',
     Hourly_rate:'السعر بالساعة',
     Enter_Hourly_rate:'أدخل سعر الساعة',
     Basic_salary:'الراتب الاساسي',
     Enter_Basic_salary:'أدخل الراتب الأساسي',
     Social_Media:'وسائل التواصل الاجتماعي',
     Skype:'سكايب',
     Enter_Skype:'أدخل سكايب',
     Facebook:'فيسبوك',
     Enter_Facebook:'أدخل الفيسبوك',
     WhatsApp:'واتس اب',
     Enter_WhatsApp:'أدخل واتسآب',
     LinkedIn:'لينكد إن',
     Enter_LinkedIn:'أدخل لينكد إن',
     Twitter:'تويتر',
     Enter_Twitter:'أدخل تويتر',
     Experiences:'الخبرات',
     bank_account:'حساب البنك',
     Company_Name:'اسم الشركة',
     Location:'الموقع',
     Enter_location:'أدخل الموقع',
     Enter_Description:'أدخل الوصف',
     Bank_Name:'اسم البنك',
     Enter_Bank_Name:'أدخل اسم البنك',
     Bank_Branch:'فرع البنك',
     Enter_Bank_Branch:'أدخل فرع البنك',
     Bank_Number:'رقم البنك',
     Enter_Bank_Number:'أدخل رقم البنك',
     Assigned_warehouses:'المخازن المخصصة',
     Top_customers:'أفضل العملاء',
     Attachment:'المرفق',
     view_employee:'مشاهدة الموظفين',
     edit_employee:'تحرير الموظفين',
     delete_employee:'حذف الموظفين',
     
    Created_by : 'تمت الإضافة بواسطة',
    Add_product_IMEI_Serial_number:'أضف الرقم التسلسلي للمنتج',
    Product_Has_Imei_Serial_number:'المنتج له رقم تسلسلي',
    IMEI_SN:'الرقم التسلسلي',
    Shipments:'الشحنات',
    delivered_to:'سلمت إلى',
    shipment_ref:'مرجع الشحنة',
    sale_ref:'المرجع للبيع',
    Edit_Shipping:'تحرير الشحن',
    Packed:'معبأة',
    Shipped:'تم الشحن',
    Delivered:'تم التوصيل',
    Cancelled:'ألغيت',
    Shipping_status:'حالة الشحن',
    Users_Report:'تقرير المستخدمين',
    stock_report:'تقرير المخزون',
    TotalPurchases:'إجمالي المشتريات',
    Total_quotations:'إجمالي عروض الأسعار',
    Total_return_sales:'إجمالي عوائد المبيعات',
    Total_return_purchases:'إجمالي عوائد المشتريات',
    Total_transfers:'إجمالي التحويلات',
    Total_adjustments:'إجمالي التعديلات',
    User_report:'تقرير المستخدم',
    Current_stock:'المخزون الحالي',
    product_name:'اسم المنتج',
    Total_Customers_Due:'إجمالي الديون',
    Total_Suppliers_Due:'إجمالي الديون',
    Some_warehouses:'بعض المستودعات',
    All_Warehouses:'جميع المستودعات',
    Product_Cost:'تكلفة المنتج',


    sms_settings:'إعدادات الرسائل القصيرة',
    pos_settings:'إعدادات نقاط البيع',
    payment_gateway:'بوابة الدفع',
    mail_settings:'إعدادات البريد',
    Nexmo_SMS:'Nexmo SMS',
    TWILIO_SMS:'TWILIO SMS',
    Default_SMS_Gateway:'بوابة الرسائل القصيرة الافتراضية',
    module_settings:'إعدادات الوحدة',
    Module_enabled_success:'تم تفعيل الوحدة بنجاح',
    Module_Disabled_success:'تم تعطيل الوحدة النمطية بنجاح',
    update_settings:'إعدادات التحديث',
    Please_Upload_the_Correct_Module:'يرجى تحميل الوحدة الصحيحة',
    Uploaded_Success:'تم الرفع بنجاح',
    Customer_details:'تفاصيل العميل',
    Edit_Customer:'تحرير العميل',
    Delete_Customer:'حذف العميل',
    Pay_Due:'الدفع المستحق',
    Paying_amount_is_greater_than_Total_Due:'مبلغ الدفع أكبر من إجمالي المبلغ المستحق',
    Customer_Invoice:'فاتورة العميل',
    This_Product_Not_For_Selling:'هذا المنتج ليس للبيع',
    Welcome_to_your_Dashboard:'مرحبًا بك في لوحة التحكم',
    Total_Payable:'إجمالي الدفع',
    Choose_Sale_Ref:'اختر مرجع البيع',
    Please_Select_Sale:'يرجى تحديد البيع',
    Sale_Ref:'مرجع البيع',
    list_product_returns:'قائمة المنتجات المرتجعة',
    Qty_return:'إرجاع الكمية',
    Choose_Purchase_Ref:'اختر مرجع الشراء',
    Purchase_Ref:'مرجع الشراء',
    please_select_purchase:'يرجى تحديد شراء',
    qty_return_is_greater_than_qty_purchased:'الكمية المرتجعة أكبر من الكمية المشتراة',
    qty_return_is_greater_than_qty_sold:'الكمية المرتجعة أكبر من الكمية المباعة',
    Quantity_sold:'الكمية المباعة',
    qty_purchased:'الكمية المشتراة',
    Sender_Name:'اسم المرسل',
    Upload_Module:'تحميل الوحدة',
    The_module_must_be_uploaded_as_zip_file:'يجب تحميل الوحدة كملف مضغوط',
    Upload_Module:'تحميل الوحدة',
    All_Modules_Installed:'جميع الوحدات المثبتة',
    Current_Version:'النسخة الحالية',
    You_already_have_the_latest_version:'لديك بالفعل أحدث إصدار',
    Update_Available:'التحديث متاح',
    Update_Log:'سجل التحديث',
    Update_Now:'تحديث الان',
    View_Change_Log:'مشاهدة سجل التغيير',
    invoice_footer:'تذييل الفاتورة',
    Please_Wait_for_the_update :'ملاحظة: يرجى انتظار انتهاء التحديث تمامًا. لا يوصى بإغلاق المتصفح أو إيقاف عملية الترقية',
    Note_update :'ملاحظة: تأكد من الاحتفاظ بنسخة احتياطية من الإصدار الحالي وقاعدة البيانات قبل تشغيل الترقية ، لاستعادتها إذا كان هناك خطأ',
    Time_Zone:'المنطقة الزمنية',
    Payment_note:'ملاحظات الدفع',
    sale_note:'ملاحظات البيع',
    Total_Sale_Due:'إجمالي ديون البيع',
    Total_Sell_Return_Due:'إجمالي الديون عائد البيع',
    pay_all_sell_due_at_a_time:'دفع جميع ديون البيع في وقت واحد',
    pay_all_sell_return_due_at_a_time:'دفع جميع ديون عائد البيع في وقت واحد',
    Gross_Profit:'اجمالي الربح',
    pay_all_purchase_due_at_a_time:'دفع جميع ديون الشراء في وقت واحد',
    Delete_Provider:'حذف المورد',
    Edit_Provider:'تحرير المورد',
    Provider_details:'تفاصيل المورد',
    Total_Purchase_Due:'إجمالي ديون الشراء',
    Provider_Credit_Note:'إشعار ائتمان المورد',
    Customer_Credit_Note:'إشعار ائتمان العميل',
    Sell_Return:'عائد بيع',
    Purchase_Return:'إرجاع الشراء',
    Tax_Number:'الرقم الضريبي',
    Please_add_return_quantity:'الرجاء إضافة الكمية المرتجعة',
    Return_exist_for_the_Transaction:'العودة موجودة في هذه الصفقة',
    qty_return_is_greater_than_Quantity_Remaining:'الكمية المرتجعة أكبر من الكمية المتبقية',
    products_refunded_alert:'لن يتم رد أي منتجات ذات كمية محددة على 0',
    pay_all_purchase_return_due_at_a_time:'دفع جميع ديون عوائد الشراء في وقت واحد',
    Total_Purchase_Return_Due:'إجمالي ديون عوائد الشراء',
    Purchase_return_due:'ديون عوائد الشراء',
    Sell_return_due:'ديون عوائد البيع',
    product_report:'تقرير المنتجات',
    product_sales_report:'تقرير مبيعات المنتج',
    Product_purchases_report:'تقرير مشتريات المنتج',
    Qty_sold:'الكمية المباعة',
    Qty_purchased:'الكمية المشتراة',
    Filter_by_warehouse:'تصفية بالمستودع',
    Enable_Print_Invoice:'قم بتمكين طباعة الفاتورة تلقائيًا',
    Show_Warehouse:'عرض المستودع',
    credit_card_info:'معلومات بطاقة الائتمان',
    Saved_Credit_Card_Info:'معلومات بطاقة الائتمان المحفوظة',
    Credit_card_changed_successfully:'تم تغيير بطاقة الائتمان بنجاح',
    notification_template:'قالب الإشعارات',
    email_notification:'إشعار بالبريد الإلكتروني',
    sms_notification:'إشعار بالرسائل القصيرة (SMS)',
    Change_product_details:'تغيير تفاصيل المنتج',
    edit_tax_and_discount_and_shipping:'تحرير الضرائب والخصومات والشحن',
    Create_Quotation_with_Stock:'إنشاء عرض أسعار مع الأسهم',
    Variant_Name:'اسم المتغير',
    Variant_cost:'تكلفة المتغير',
    Variant_price:'سعر المتغير',
    Variant_code:'رمز المتغير',
    Please_wait_until_the_product_is_loaded:'يرجى الانتظار حتى يتم تحميل المنتج',
    Choose_SMS_Gateway:'اختر بوابة SMS',
    Notification_Client:'إشعار العميل',
    Available_Tags:'العلامات المتاحة',
    Email_Subject:'عنوان البريد الإلكتروني',
    Email_body:'محتوى البريد الإلكتروني',
    Notification_Supplier:'إشعار المورد',
    sms_body:'محتوى الرسالة القصيرة (SMS)',
    sms_templates:'قوالب الرسائل القصيرة',
    email_templates:'قوالب البريد الإلكتروني',
};