//Language Russian

export default {
    Receipt:'Чек',
    Pos_Settings:'Настройки точки продаж',
    Note_to_customer:'Примечание для покупателя',
    Show_Note_to_customer:'Показать заметку покупателю',
    Show_barcode:'Показать штрих-код',
    Show_Tax_and_Discount:'Показать налог, скидки и доставка',
    Show_Customer:'Показать клиента',
    Show_Email:'Показать электронную почту',
    Show_Phone:'Показать телефон',
    Show_Address:'Показать адрес',
    DefaultLanguage:'Язык по умолчанию',
    footer:'нижний колонтитул',
    Received_Amount:'Полученная сумма',
    Paying_Amount:'Сумма платежа',
    Change:'Изменять',
    Paying_amount_is_greater_than_Received_amount:'Сумма платежа больше полученной суммы',
    Paying_amount_is_greater_than_Grand_Total:'Сумма платежа превышает общую сумму',
    code_must_be_not_exist_already:'код уже не должен существовать',
    You_will_find_your_backup_on:'Вы найдете резервную копию на',
    and_save_it_to_your_pc:'и сохраните на свой компьютер',
    Scan_your_barcode_and_select_the_correct_symbology_below:'Отсканируйте свой штрих-код и выберите правильную символику ниже',
    Scan_Search_Product_by_Code_Name:'Сканирование / поиск продукта по кодовому названию',
    Paper_size:'Размер бумаги',
    Clear_Cache:'Очистить кэш',
    Cache_cleared_successfully:'Кеш успешно очищен',
    Failed_to_clear_cache:'Не удалось очистить кеш',
    Scan_Barcode:'Сканер штрих-кода',
    Please_use_short_name_of_unit:'Пожалуйста, используйте короткое название единицы',
    DefaultCustomer:'Клиент по умолчанию',
    DefaultWarehouse:'Склад по умолчанию',
    Payment_Gateway:'Платежный шлюз',
    SMS_Configuration:'Конфигурация SMS',
    Gateway:'Платежный шлюз',
    Choose_Gateway:'Выберите платежный шлюз',
    Send_SMS :'Сообщение успешно отправлено',
    sms_config_invalid:'неверная конфигурация смс недействительна',
    Remove_Stripe_Key_Secret:'Удалить ключи API Stripe',
    credit_card_account_not_available :'Счет кредитной карты недоступен',
    Credit_Card_Info:'Информация о кредитной карте',
    developed_by:'Разработан',
    Unit_already_linked_with_sub_unit:'Объект уже связан с дополнительным блоком',
    Total_Items_Quantity : 'Итого и количество',
    Value_by_Cost_and_Price : 'Соотношение затрат и цен',
    Search_this_table: 'Искать в этой таблице',
    import_products:'Импортные товары',
    Field_optional:'Поле необязательно',
    Download_exemple:'Скачать пример',
    field_must_be_in_csv_format:'Поле должно быть в формате csv.',
    Successfully_Imported:'Успешно импортировано',
    file_size_must_be_less_than_1_mega:'Размер файла не должен превышать 1 мегабайт.',
    Please_follow_the_import_instructions:'Следуйте инструкциям по импорту',
    must_be_exist:'юнит уже должен быть создан',
    Import_Customers:'Импортные клиенты',
    Import_Suppliers:'Поставщики импорта',
    Recent_Sales : 'Последние продажи',
    Create_Transfer : 'Создать перевод',
    order_products : 'элементы заказа',
    Search_Product_by_Code_Name : 'Поиск продукта по коду или названию',
    Reports_payments_Purchase_Return:'Отчеты о возвратных платежах за покупку',
    Reports_payments_Sale_Return:'Отчеты о продажах и возвратах платежей',
    payments_Sales_Return:'возврат платежей',
    payments_Purchases_Return:'платежи покупки возврат',
    CreateSaleReturn:'Создать возврат продажи',
    EditSaleReturn:'Изменить возврат продажи',
    SalesReturn:'Возвращение продаж',
    CreatePurchaseReturn:'Создать возврат покупки',
    EditPurchaseReturn:'Изменить возврат покупки',
    PurchasesReturn:'Покупки Возврат',
    Due:'должный',
    Profit: 'Выгода',
    Revenue: 'Доход',
    Sales_today: 'Продажи сегодня',
    People: 'Люди',
    Successfully_Created: 'Успешно создано',
    Successfully_Updated: 'Успешно обновлено',
    Success: 'Успех',
    Failed: 'Не удалось',
    Warning: 'Warning',
    Please_fill_the_form_correctly: 'Пожалуйста, заполните форму правильно',
    Field_is_required: 'Поле, обязательное для заполнения',
    Error: 'Ошибка!',
    you_are_not_authorized: 'Извини! вы не авторизованы.',
    Go_back_to_home: 'Вернуться на главную',
    page_not_exist: 'Извини! Страница, которую вы искали, не существует.',
    Choose_Status: 'Выберите статус',
    Choose_Method: 'Выберите метод',
    Choose_Symbology: 'Выберите символы',
    Choose_Category: 'Выберите категорию',
    Choose_Customer: 'Выберите клиента',
    Choose_Supplier: 'Выберите поставщика',
    Choose_Unit_Purchase: 'Выберите покупную единицу',
    Choose_Sub_Category: 'Выберите подкатегорию',
    Choose_Brand: 'Выберите марку',
    Choose_Warehouse: 'Выберите склад',
    Choose_Unit_Sale: 'Выберите единицу продажи',
    Enter_Product_Cost: 'Введите стоимость продукта',
    Enter_Stock_alert: 'Введите оповещение о наличии акций',
    Enter_Quantity: 'Введите количество',
    Warehouses_Master: 'Склад Мастер',
    Choose_Unit_Product: 'Выберите единицу продукции',
    Enter_Product_Price: 'Введите цену продукта',
    Enter_Name_Product: 'Введите название продукта',
    Enter_Role_Name: 'Введите имя роли',
    Enter_Role_Description: 'Введите описание роли',
    Enter_name_category: 'Введите название категории',
    Enter_Code_category: 'Введите код категории',
    Enter_Name_Brand: 'Введите название бренда',
    Enter_Description_Brand: 'Введите описание бренда',
    Enter_Code_Currency: 'Введите валюту кода',
    Enter_name_Currency: 'Введите имя Валюта',
    Enter_Symbol_Currency: 'Введите валюту символа',
    Enter_Name_Unit: 'Введите название объекта',
    Enter_ShortName_Unit: 'Введите короткое имя',
    Choose_Base_Unit: 'Выберите базовый блок',
    Choose_Operator: 'Выберите оператора',
    Enter_Operation_Value: 'Введите значение операции',
    Enter_Name_Warehouse: 'Введите название склада',
    Enter_Phone_Warehouse: 'Введите телефон склада',
    Enter_Country_Warehouse: 'Укажите страну склада',
    Enter_City_Warehouse: 'Введите город склада',
    Enter_Email_Warehouse: 'Введите адрес электронной почты склада',
    Enter_ZipCode_Warehouse: 'Введите почтовый индекс склада',
    Choose_Currency: 'Выберите Валюту',
    Thank_you_for_your_business: 'Спасибо за ваш бизнес!',
    Cancel: 'Отмена',
    New_Customer: 'Новый покупатель',
    Incorrect_Login: 'Неправильный логин',
    Successfully_Logged_In: 'Успешный вход в систему',
    This_user_not_active: 'Этот пользователь не активен',
    SignIn: 'Войти',
    Create_an_account: 'Завести аккаунт',
    Forgot_Password: 'Забыл пароль ?',
    Email_Address: 'Адрес электронной почты',
    SignUp: 'Зарегистрироваться',
    Already_have_an_account: 'Уже есть аккаунт?',
    Reset_Password: 'Сброс пароля',
    Failed_to_authenticate_on_SMTP_server: 'Не удалось пройти аутентификацию на SMTP-сервере',
    We_cant_find_a_user_with_that_email_addres: 'Мы не можем найти пользователя с таким адресом электронной почты',
    We_have_emailed_your_password_reset_link: 'Мы отправили вам ссылку для сброса пароля по электронной почте',
    Please_fill_the_Email_Adress: 'Пожалуйста, заполните адрес электронной почты',
    Confirm_password: 'Подтвердить Пароль',
    Your_Password_has_been_changed: 'Ваш пароль был изменен',
    The_password_confirmation_does_not_match: 'Подтверждение пароля не совпадает',
    This_password_reset_token_is_invalid: 'Этот токен сброса пароля недействителен',
    Warehouse_report: 'Отчет о складе',
    All_Warehouses: 'Все склады',
    Expense_List: 'Список расходов',
    Expenses: 'Затраты',
    This_Week_Sales_Purchases: 'Продажи и покупки на этой неделе',
    Top_Selling_Products: 'Самые продаваемые товары',
    View_all: 'Посмотреть все',
    Payment_Sent_Received: 'Платеж отправлен и получен',
    Filter: 'Фильтр',
    Invoice_POS: 'Счет-фактура POS',
    Invoice: 'Выставленный счет',
    Customer_Info: 'Информация о клиенте',
    Company_Info: 'информация о компании',
    Invoice_Info: 'Информация о счете',
    Order_Summary: 'итог заказа',
    Quote_Info: 'Ценовая информация',
    Del: 'удалять',
    SuppliersPaiementsReport: 'Отчет о платежах поставщикам',
    Purchase_Info: 'Информация о покупке',
    Supplier_Info: 'Информация о поставщиках',
    Return_Info: 'информация о возврате',
    Expense_Category: 'Категория расходов',
    Create_Expense: 'Создать расход',
    Details: 'Детали',
    Discount_Method: 'Метод скидки',
    Net_Unit_Cost: 'Чистая стоимость единицы',
    Net_Unit_Price: 'Чистая цена за единицу',
    Edit_Expense: 'Изменить расходы',
    All_Brand: 'Все марки',
    All_Category: 'Все категории',
    ListExpenses: 'Перечислить расходы',
    Create_Permission: 'Создать разрешение',
    Edit_Permission: 'Изменить разрешение',
    Reports_payments_Sales: 'Отчеты о платежах Продажи',
    Reports_payments_Purchases: 'Отчеты о платежах Покупки',
    Reports_payments_Return_Customers: 'Отчеты о платежах Возврат клиентов',
    Reports_payments_Return_Suppliers: 'Отчеты о платежах Возврат Поставщикам',
    Expense_Deleted: 'Этот расход был удален',
    Expense_Updated: 'Этот расход был обновлен',
    Expense_Created: 'Этот расход был создан',
    DemoVersion: 'Вы не можете этого сделать в демо-версии',
    OrderStatistics: 'Статистика продаж',
    AlreadyAdd: 'Этот продукт уже добавлен!',
    AddProductToList: 'Пожалуйста, добавьте товар в список!',
    AddQuantity: 'Пожалуйста, добавьте количество деталей !!',
    InvalidData: 'Неверные данные !!',
    LowStock: 'количество превышает количество на складе',
    WarehouseIdentical: 'Два склада не могут быть идентичными !!',
    VariantDuplicate: 'Этот вариант дублируется !!',
    Filesize: 'Размер файла',
    GenerateBackup: 'Создать резервную копию',
    BackupDatabase: 'Резервная база данных',
    Backup: 'резервное копирование',
    Paid: 'Платный',
    Unpaid: 'Неоплаченный',
    Today: 'Cегодня',
    Income: 'Доход',
    Expenses: 'Затраты',
    Sale: 'распродажа',
    Actif: 'Активный',
    Inactif: 'Неактивный',
    Customers: 'Клиенты',
    Phone: 'Телефон',
    SearchByPhone: 'Поиск по телефону',
    Suppliers: 'Поставщики',
    Quotations: 'Котировки',
    Sales: 'Продажи',
    Purchases: 'Покупки',
    Returns: 'Возврат',
    Settings: 'Настройки',
    SystemSettings: 'Системные настройки',
    Users: 'Пользователи',
    GroupPermissions: 'Групповые разрешения',
    Currencies: 'Валюты',
    Warehouses: 'Склады',
    Units: 'Единицы',
    UnitsPrchases: 'Покупка единиц',
    UnitsSales: 'Единицы продаж',
    Reports: 'Отчеты',
    PaymentsReport: 'Отчет о платежах',
    PaymentsPurchases: 'Платежи Покупки',
    PaymentsSales: 'Платежи Продажи',
    ProfitandLoss: 'Прибыль и убыток',
    WarehouseStockChart: 'График складских запасов',
    SalesReport: 'Отчет о продажах',
    PurchasesReport: 'Отчет о закупках',
    CustomersReport: 'Отчет клиентов',
    SuppliersReport: 'Отчет о поставщиках',
    SupplierReport: 'Отчет поставщика',
    DailySalesData: 'Ежедневные данные о продажах',
    DailyPurchasesData: 'Данные о ежедневных покупках',
    Dernièrescinqrecords: 'Последние пять рекордов',
    Filters: 'Фильтры',
    date: 'Дата',
    Reference: 'Справка',
    Supplier: 'Поставщик',
    PaymentStatus: 'Статус платежа',
    Customer: 'Покупатель',
    CustomerCode: 'Код клиента',
    Status: 'Положение дел',
    SupplierCode: 'Код поставщиком',
    Categorie: 'Категория',
    Categories: 'Категории',
    StockTransfers: 'Передача запасов',
    StockManagement: 'Управление запасами',
    dashboard: 'Щиток приборов',
    Products: 'Товары',
    productsList: 'Список продуктов',
    ProductManagement: 'Управление продуктом',
    ProductQuantityAlerts: 'Оповещения о количестве товаров',
    CodeProduct: 'Код продукта',
    ProductTax: 'Налог на продукцию',
    SubCategorie: 'Подкатегория',
    Name_product: 'Обозначение',
    StockAlert: 'Уведомление о наличии запасов',
    warehouse: 'склад',
    Tax: 'Налог',
    BuyingPrice: 'Цена покупки',
    SellPrice: 'Цена продажи',
    Quantity: 'Количество',
    UnitSale: 'Продажа единиц',
    UnitPurchase: 'Покупка единицы',
    ManagementCurrencies: 'Валютный менеджмент',
    CurrencyCode: 'Код валюты',
    CurrencyName: 'Название валюты',
    Symbol: 'Символ',
    All: 'Все',
    EditProduct: 'Редактировать продукт',
    SearchByCode: 'Поиск по коду',
    SearchByName: 'Поиск по имени',
    ProductDetails: 'информация о продукте',
    CustomerName: 'Имя Клиента',
    CustomerManagement: 'Управление клиентами',
    Add: 'Создайте',
    add: 'Создайте',
    Edit: 'редактировать',
    Close: 'близко',
    PleaseSelect: 'Пожалуйста выберите',
    Action: 'Действие',
    Email: 'Электронное письмо',
    EditCustomer: 'Изменить клиента',
    AddCustomer: 'Создать клиента',
    Country: 'Страна',
    City: 'город',
    Adress: 'Адрес',
    CustomerDetails: 'Детали клиента',
    CustomersList: 'Список клиентов',
    SupplierCode: 'Код поставщиком',
    SupplierName: 'наименование поставщика',
    SuppliersManagement: 'Управление поставщиками',
    SupplierDetails: 'Детали поставщика',
    QuotationsManagement: 'Управление котировками',
    SubTotal: 'Промежуточный итог',
    MontantReste: 'Осталась сумма',
    complete: 'завершено',
    EnAttendant: 'в ожидании',
    Recu: 'Получила',
    partial: 'Частичное',
    Retournee: 'Возвращение',
    DetailQuote: 'Подробная цитата',
    EditQuote: 'Изменить цитату',
    CreateSale: 'Создать распродажу',
    DownloadPdf: 'Скачать Pdf',
    QuoteEmail: 'Предложение отправлено по электронной почте',
    DeleteQuote: 'Удалить предложение',
    AddQuote: 'Создать предложение',
    SelectProduct: 'Выбрать продукт',
    ProductCodeName: 'Продукт (Код - Название)',
    Price: 'Цена',
    CurrentStock: 'Склад',
    Total: 'Общее',
    Num: '№',
    Unitcost: 'стоимость единицы',
    to: 'к',
    Subject: 'Предмет',
    Message: 'Сообщение',
    EmailCustomer: 'Электронная почта клиента',
    Sent: 'послать',
    Quote: 'Цитата',
    Hello: 'Здравствуйте',
    AttachmentQuote: 'Пожалуйста, найдите приложение к вашему предложению',
    AddProducts: 'Добавить товары в список заказов',
    SelectWarehouse: 'Пожалуйста, выберите склад',
    SelectCustomer: 'пожалуйста, выберите клиента',
    SalesManagement: 'Управление продажами',
    Balance: 'Остаток средств',
    QtyBack: 'Назад Количество',
    TotalReturn: 'Общий доход',
    Amount: 'Количество',
    SaleDetail: 'Детали продажи',
    EditSale: 'Изменить распродажу',
    AddSale: 'Создать распродажу',
    ShowPayment: 'Показать платежи',
    AddPayment: 'Создать платеж',
    EditPayment: 'Изменить платеж',
    EmailSale: 'Отправить распродажу по электронной почте',
    DeleteSale: 'Удалить распродажу',
    ModePaiement: 'Оплачивается',
    Paymentchoice: 'Выбор платежа',
    Note: 'Заметка',
    PaymentComplete: 'Оплата завершена!',
    PurchasesManagement: 'Управление закупками',
    Ordered: 'Упорядоченный',
    DeletePurchase: 'Удалить покупку',
    EmailPurchase: 'Отправить покупку по электронной почте',
    EditPurchase: 'Изменить покупку',
    PurchaseDetail: 'Детали покупки',
    AddPurchase: 'Создать покупку',
    EmailSupplier: 'Электронная почта поставщика',
    PurchaseInvoice: 'Оплата покупок',
    PurchasesInvoicesData: 'Данные о платежах покупок',
    SalesInvoice: 'Платежи за продажу',
    SalesInvoicesData: 'Данные о продажах',
    UserManagement: 'управление пользователями',
    Firstname: 'Имя',
    lastname: 'фамилия',
    username: 'Имя пользователя',
    password: 'пароль',
    Newpassword: 'Новый пароль',
    ChangeAvatar: 'Сменить аватар',
    LeaveBlank: 'Оставьте это поле пустым, если вы его не меняли',
    type: 'тип',
    UserPermissions: 'Разрешения пользователей',
    RoleName: 'Роль',
    RoleDescription: 'Описание роли',
    AddPermissions: 'Создать разрешения',
    View: 'Посмотреть',
    Del: 'удалять',
    NewAdjustement: 'Новая корректировка',
    EditAdjustement: 'Изменить настройку',
    CannotSubstraction: 'Вы не можете вычесть продукты, у которых есть запас 0',
    Addition: 'Дополнение',
    Subtraction: 'Вычитание',
    profil: 'профиль',
    logout: 'выйти',
    PurchaseAlreadyPaid: 'вы не можете изменить, потому что эта покупка уже оплачена',
    SaleAlreadyPaid: 'вы не можете изменить, потому что эта Распродажа уже оплачена',
    ReturnAlreadyPaid: 'вы не можете изменить, потому что этот возврат уже оплачен',
    QuoteAlready: 'Эта цитата уже привела к продаже',
    AddProduct: 'Создать продукт',
    QuotationComplete: 'Цитата завершена',
    SiteConfiguration: 'Конфигурация сайта',
    Language: 'Язык',
    DefaultCurrency: 'Валюта по умолчанию',
    LoginCaptcha: 'Войти Captcha',
    DefaultEmail: 'Почта по умолчанию',
    SiteName: 'Название сайта',
    ChangeLogo: 'Изменить логотип',
    SMTPConfiguration: 'Конфигурация SMTP',
    HOST: 'ВЕДУЩИЙ',
    PORT: 'ПОРТ',
    encryption: 'Шифрование',
    SMTPIncorrect: 'Неправильная конфигурация SMTP',
    PaymentsReturns: 'Платежи Возврат',
    ReturnsInvoices: 'Возврат счетов',
    ReturnsInvoicesData: 'Возвращает данные счетов-фактур',
    ShowAll: 'Показать все записи всех пользователей',
    Discount: 'Скидка',
    OrderTax: 'Налог на заказ',
    Shipping: 'Перевозка',
    CompanyName: 'Название компании',
    CompanyPhone: 'Телефон компании',
    CompanyAdress: 'Адрес компании',
    Code: 'Код',
    image: 'образ',
    Printbarcode: 'Распечатать штрих-код',
    ReturnsCustomers: 'Возвращает клиентов',
    ReturnsSuppliers: 'Возврат Поставщикам',
    FactureReturnCustomers: 'Возврат счета-фактуры клиента',
    FactureReturnSuppliers: 'Счет-фактура возврата поставщика',
    NodataAvailable: 'Данные недоступны',
    ProductImage: 'Изображение продукта',
    Barcode: 'Штрих-код',
    pointofsales: 'Точка продаж',
    CustomUpload: 'Пользовательская загрузка',
    pointofsaleManagement: 'точка управления продажами',
    Adjustment: 'Регулировка',
    Updat: 'Обновить',
    Reset: 'Сброс настроек',
    print: 'Распечатать',
    SearchByEmail: 'Поиск по почте',
    ChooseProduct: 'Выберите продукт',
    Qty: 'Кол-во',
    Items: 'Предметы',
    AmountHT: 'Количество',
    AmountTTC: 'общая сумма',
    PleaseSelectSupplier: 'Пожалуйста, выберите поставщика',
    PleaseSelectStatut: 'Пожалуйста, выберите статус',
    PayeBy: 'Оплачивается',
    ChooseWarehouse: 'Выберите склад',
    payNow: 'Заплатить сейчас',
    ListofCategory: 'Список категорий',
    Description: 'Описание',
    submit: 'представить',
    ProblemCreatingThisInvoice: 'При создании этого счета возникла проблема. Пожалуйста, попробуйте еще раз',
    ProblemPayment: 'Возникла проблема с оплатой. Пожалуйста, попробуйте еще раз.',
    IncomeExpenses: 'Доходы и расходы',
    dailySalesPurchases: 'Ежедневные продажи и покупки',
    ProductsExpired: 'Срок действия продуктов истек',
    ListofBrand: 'Список брендов',
    CreateAdjustment: 'Создать корректировку',
    Afewwords: 'Несколько слов ...',
    UserImage: 'Изображение пользователя',
    UpdateProduct: 'Обновить продукт',
    Brand: 'Марка',
    BarcodeSymbology: 'Символика штрих-кода',
    ProductCost: 'Стоимость продукта',
    ProductPrice: 'Цена продукта',
    UnitProduct: 'Единица продукта',
    TaxMethod: 'Налоговый метод',
    MultipleImage: 'Несколько изображений',
    ProductHasMultiVariants: 'Продукт имеет несколько вариантов',
    ProductHasPromotion: 'У продукта есть продвижение',
    PromotionStart: 'Начало продвижения',
    PromotionEnd: 'Конец акции',
    PromotionPrice: 'Цена акции',
    Price: 'Цена',
    Cost: 'Стоимость',
    Unit: 'Ед. изм',
    ProductVariant: 'Вариант продукта',
    Variant: 'Вариант',
    UnitPrice: 'Цена за единицу',
    CreateReturnCustomer: 'Создать возвратного клиента',
    EditReturnCustomer: 'Изменить возвратного клиента',
    CreateReturnSupplier: 'Создать поставщика возврата',
    Documentation: 'Документация',
    EditReturnSupplier: 'Изменить поставщика возврата',
    FromWarehouse: 'Со склада',
    ToWarehouse: 'На склад',
    EditTransfer: 'Редактировать перевод',
    TransferDetail: 'Детали перевода',
    Pending: 'В ожидании',
    Received: 'Получила',
    Ordered: 'Упорядоченный',
    PermissionsManager: 'Управление разрешениями',
    BrandManager: 'Марка',
    BrandImage: 'Фирменное изображение',
    BrandName: 'Имя бренда',
    BrandDescription: 'Описание бренда',
    BaseUnit: 'Базовый блок',
    ManagerUnits: 'Управление подразделениями',
    OperationValue: 'Значение операции',
    Operator: 'Оператор',
    Top5Products: 'Пять лучших продуктов',
    Last5Sales: 'Последние пять продаж',
    ListAdjustments: 'Список корректировок',
    ListTransfers: 'Список переводов',
    CreateTransfer: 'Создать перевод',
    OrdersManager: 'Управление заказами',
    ListQuotations: 'Список котировок',
    ListPurchases: 'Список покупок',
    ListSales: 'Список продаж',
    ListReturns: 'Список возвратов',
    PeopleManager: 'Управление персоналом',



    //sweet Alert
    Delete: {
        Title: 'Ты уверен?',
        Text: 'Вы не сможете отменить это!',
        confirmButtonText: 'Да удалите!',
        cancelButtonText: 'Отмена',
        Deleted: 'Удалено!',
        Failed: 'Не удалось!',
        Therewassomethingwronge: 'Что-то было не так',
        CustomerDeleted: 'этот Клиент был удален.',
        SupplierDeleted: 'этот поставщик был удален.',
        QuoteDeleted: 'Цитата удалена.',
        SaleDeleted: 'эта распродажа была удалена.',
        PaymentDeleted: 'этот Платеж был удален.',
        PurchaseDeleted: 'эта покупка была удалена.',
        ReturnDeleted: 'этот возврат был удален.',
        ProductDeleted: 'этот продукт был удален.',
        ClientError: 'Этот клиент уже связан с другой операцией',
        ProviderError: 'Этот поставщик уже связан с другой операцией',
        UserDeleted: 'Этот пользователь был удален.',
        UnitDeleted: 'Этот блок был удален.',
        RoleDeleted: 'Эта роль удалена.',
        TaxeDeleted: 'Этот налог был удален.',
        SubCatDeleted: 'Эта подкатегория была удалена.',
        CatDeleted: 'Эта категория была удалена.',
        WarehouseDeleted: 'Этот склад был удален.',
        AlreadyLinked: 'этот продукт уже связан с другой операцией',
        AdjustDeleted: 'Эта корректировка была удалена.',
        TitleCurrency: 'Эта валюта была удалена.',
        TitleTransfer: 'Перевод успешно удален',
        BackupDeleted: 'Резервная копия была успешно удалена',
        TitleBrand: 'Этот бренд был удален',

    },
    Update: {
        TitleBrand: 'Этот бренд был обновлен',
        TitleProfile: 'Ваш профиль успешно обновлен',
        TitleAdjust: 'Настройка успешно обновлена',
        TitleRole: 'Роль обновлена успешно',
        TitleUnit: 'Объект успешно обновлен',
        TitleUser: 'Пользователь успешно обновлен',
        TitleCustomer: 'Обновление клиента успешно',
        TitleQuote: 'Предложение обновлено успешно',
        TitleSale: 'Распродажа успешно обновлена',
        TitlePayment: 'Платеж успешно обновлен',
        TitlePurchase: 'Покупка успешно обновлена',
        TitleReturn: 'Возврат Обновлено успешно',
        TitleProduct: 'Обновление продукта успешно выполнено',
        TitleSupplier: 'Поставщик успешно обновлен',
        TitleTaxe: 'Налог обновлен успешно',
        TitleCat: 'Категория обновлена успешно',
        TitleWarhouse: 'Склад успешно обновлен',
        TitleSetting: 'Настройки обновлены успешно',
        TitleCurrency: 'Обновление валюты успешно',
        TitleTransfer: 'Перенос успешно обновлен',
    },

    Create: {
        TitleBrand: 'Этот бренд был создан',
        TitleRole: 'Роль успешно создана',
        TitleUnit: 'Объект успешно создан',
        TitleUser: 'Пользователь успешно создан в',
        TitleCustomer: 'Клиент успешно создан',
        TitleQuote: 'Предложение создано в успешно',
        TitleSale: 'Распродажа успешно создана',
        TitlePayment: 'Платеж успешно создан',
        TitlePurchase: 'Покупка успешно создана',
        TitleReturn: 'Возврат успешно создан',
        TitleProduct: 'Продукт успешно создан',
        TitleSupplier: 'Поставщик успешно создан',
        TitleTaxe: 'Налог создан в успешно',
        TitleCat: 'Категория создана в успешно',
        TitleWarhouse: 'Склад успешно создан',
        TitleAdjust: 'Корректировка успешно создана',
        TitleCurrency: 'Валюта успешно создана',
        TitleTransfer: 'Перевод успешно создан',
    },
    Send: {
        TitleEmail: 'Электронная почта отправлена успешно',
    },
    return: {
        TitleSale: 'эта распродажа уже связана с возвратом!',
    },
    ReturnManagement: 'Управление возвратом',
    ReturnDetail: 'Деталь возврата',
    EditReturn: 'Изменить возврат',
    AddReturn: 'Создать возврат',
    EmailReturn: 'Отправить возврат по почте',
    DeleteReturn: 'Удалить возврат',
    Retoursurcharge: 'Return Surcharge',
    Laivrison: 'Доставка',
    SelectSale: 'Выбрать распродажу',
    ZeroPardefault: 'Вы можете удалить товар или установить возвращаемое количество равным нулю, если оно не возвращается.',
    Return: 'Возвращение',
    Purchase: 'Покупка',
    TotalSales: 'Тотальная распродажа',
    TotalPurchases: 'Всего покупок',
    TotalReturns: 'Общая прибыль',
    PaiementsNet: 'Чистые платежи',
    PaiementsSent: 'Отправленные платежи',
    PaiementsReceived: 'Платежи получены',
    Recieved: 'Получила',
    Sent: 'Отправлено',
    ProductQuantityAlerts: 'Оповещения о количестве товаров',
    ProductCode: 'Код',
    ProductName: 'Товар',
    AlertQuantity: 'Количество предупреждений',
    WarehouseStockChart: 'График складских запасов',
    TotalProducts: 'Всего продуктов',
    TotalQuantity: 'Общая численность',
    TopCustomers: 'Пятерка крупнейших клиентов',
    TotalAmount: 'Итого',
    TotalPaid: 'Общая сумма',
    CustomerSalesReport: 'Отчет о продажах клиентов',
    CustomerPaiementsReport: 'Отчет о платежах клиентов',
    CustomerQuotationsReport: 'Отчет о предложениях клиентов',
    Payments: 'Платежи',
    TopSuppliers: 'Пятерка лучших поставщиков',
    SupplierPurchasesReport: 'Отчет о закупках поставщика',
    SupplierPaiementsReport: 'Отчет о платежах поставщикам',
    Name: 'название',
    Code: 'Код',
    ManagementWarehouse: 'Управление складом',
    ZipCode: 'Почтовый Индекс',
    managementCategories: 'Управление категориями',
    Codecategorie: 'Категория кода',
    Namecategorie: 'Категория имени',
    Parentcategorie: 'Родительская категория',
    managementTax: 'Налоговый менеджмент',
    TaxName: 'Название налога',
    TaxRate: 'Ставка налога',
    managementUnitPurchases: 'Единица закупок',
    managementUnitSales: 'Отдел продаж',
    ShortName: 'Короткое имя',
    PleaseSelectThesebeforeaddinganyproduct: 'Пожалуйста, выберите их перед добавлением любого продукта',
    StockAdjustement: 'Регулировка запаса',
    PleaseSelectWarehouse: 'Пожалуйста, выберите склад, прежде чем выбрать любой продукт',
    StockTransfer: 'Передача запасов',
    SelectPeriod: 'Выберите период',
    ThisYear: 'В этом году',
    ThisToday: 'Это сегодня',
    ThisMonth: 'Этот месяц',
    ThisWeek: 'Эта неделя',
    AdjustmentDetail: 'Детали настройки',
    ActivateUser: 'Этот пользователь был активирован',
    DisActivateUser: 'Этот пользователь был деактивирован',
    NotFound: 'Страница не найдена.',
    oops: 'ошибка! Страница не найдена.',
    couldNotFind: 'Мы не смогли найти страницу, которую вы искали. Тем временем вы можете',
    ReturnDashboard: 'вернуться на панель управления',


     //hrm module
     hrm:'УЧР',
     Employees:'Сотрудники',
     Attendance:'Посещаемость',
     Leave_request:'Оставьте запрос',
     Leave_type:'Оставить Тип',
     Company:'Компания',
     Departments:'Департаменты',
     Designations:'Обозначения',
     Office_Shift:'Офисная смена',
     Holidays:'Праздники',
     Enter_Company_Name:'Введите название компании',
     Enter_email_address:'Введите адрес электронной почты',
     Enter_Company_Phone:'Введите телефон компании',
     Enter_Company_Country:'Введите страну компании',
     Created_in_successfully:'Создано успешно',
     Updated_in_successfully:'Обновлено успешно',
     Deleted_in_successfully:'Удален в успешно',
     department:'отделение',
     Enter_Department_Name:'Введите название отдела',
     Choose_Company:'Выберите компанию',
     Department_Head:'Начальник отдела',
     Choose_Department_Head:'Выбрать начальника отдела',
     Enter_Shift_name:'Введите название смены',
     Monday_In:'Monday In',
     Monday_Out:'Monday Out',
     Tuesday_In:'Tuesday In',
     tuesday_out:'tuesday Out',
     wednesday_in:'Wednesday In',
     wednesday_out:'Wednesday Out',
     thursday_in:'Thursday In',
     thursday_out:'Thursday Out',
     friday_in:'Friday In',
     friday_out:'Friday Out',
     saturday_in:'Saturday In',
     saturday_out:'Saturday Out',
     sunday_in:'Sunday In',
     sunday_out:'Sunday Out',
     Holiday:'Праздничный день',
     Enter_title:'Введите название',
     title:'заглавие',
     start_date:'Дата начала',
     Enter_Start_date:'Введите дату начала',
     Finish_Date:'Дата окончания',
     Enter_Finish_date:'Введите дату окончания',
     Please_provide_any_details:'Пожалуйста, предоставьте любую информацию',
     Attendances:'Посещаемость',
     Enter_Attendance_date:'Введите дату присутствия',
     Time_In:'Time In',
     Time_Out:'Time Out',
     Choose_Employee:'Выберите сотрудника',
     Employee:'Сотрудник',
     Work_Duration:'Длительность работы',
     remaining_leaves_are_insufficient:'Остальные листья недостаточны',
     Leave_Type:'Оставить Тип',
     Days:'Дней',
     Department:'отделение',
     Choose_leave_type:'Выберите тип отпуска',
     Choose_status:'Выберите статус',
     Leave_Reason:'Оставить причину',
     Enter_Reason_Leave:'Укажите причину Оставить',
     Add_Employee:'Добавить сотрудника',
     FirstName:'имя',
     Enter_FirstName:'Введите имя',
     LastName:'Фамилия',
     Enter_LastName:'Введите фамилию',
     Gender:'гендер',
     Choose_Gender:'Выберите пол',
     Enter_Birth_date:'Введите дату рождения',
     Birth_date:'Дата рождения',
     Enter_Country:'Введите страну',
     Enter_Phone_Number:'Введите номер телефона',
     joining_date:'Дата вступления',
     Enter_joining_date:'Введите дату присоединения',
     Choose_Designation:'Выберите обозначение',
     Designation:'Обозначение',
     Office_Shift:'Офисная смена',
     Choose_Office_Shift:'Выбрать офисную смену',
     Enter_Leaving_Date:'Введите дату отъезда',
     Leaving_Date:'Дата отъезда',
     Annual_Leave:'Ежегодный отпуск',
     Enter_Annual_Leave:'Введите ежегодный отпуск',
     Remaining_leave:'Оставшийся отпуск',
     Employee_Details:'Сведения о сотруднике',
     Basic_Information:'Основная информация',
     Family_status:'Семейный статус',
     Choose_Family_status:'Выберите семейный статус',
     Employment_type:'Вид занятости',
     Select_Employment_type:'Выберите тип занятости',
     Enter_City:'Введите город',
     Province:'Область',
     Enter_Province:'Введите провинцию',
     Enter_Address:'Введите адрес',
     Enter_Zip_code:'Введите почтовый индекс',
     Zip_code:'Почтовый индекс',
     Hourly_rate:'Почасовая ставка',
     Enter_Hourly_rate:'Введите почасовую ставку',
     Basic_salary:'Базовая заработная плата',
     Enter_Basic_salary:'Введите базовую заработную плату',
     Social_Media:'Социальные сети',
     Skype:'Скайп',
     Enter_Skype:'Введите свой скайп',
     Facebook:'Фейсбук',
     Enter_Facebook:'Введите свой Фейсбук',
     WhatsApp:'ватсап',
     Enter_WhatsApp:'Введите свой ватсап',
     LinkedIn:'ЛинкедИн',
     Enter_LinkedIn:'Введите свой ЛинкедИн',
     Twitter:'Твиттер',
     Enter_Twitter:'Введите свой Твиттер',
     Experiences:'Опыт',
     bank_account:'банковский счет',
     Company_Name:'название компании',
     Location:'положение',
     Enter_location:'Введите местоположение',
     Enter_Description:'Введите описание',
     Bank_Name:'Имя банка',
     Enter_Bank_Name:'Введите название банка',
     Bank_Branch:'Филиал банка',
     Enter_Bank_Branch:'Филиал банка',
     Bank_Number:'Номер банка',
     Enter_Bank_Number:'Номер банка',
     Assigned_warehouses:'Присвоенные склады',
     Top_customers:'Лучшие клиенты',
     Attachment:'Вложение',
     view_employee:'просмотреть сотрудников',
     edit_employee:'редактировать сотрудников',
     delete_employee:'удалить сотрудников',


    Created_by : 'Добавлено',
    Add_product_IMEI_Serial_number:'Добавить IMEI/серийный номер продукта',
    Product_Has_Imei_Serial_number:'Продукт имеет IMEI/серийный номер',
    IMEI_SN:'IMEI/SN',
    Shipments:'Отгрузки',
    delivered_to:'Доставлен в',
    shipment_ref:'Отгрузка',
    sale_ref:'Продажа Реф.',
    Edit_Shipping:'Изменить доставку',
    Packed:'упакованный',
    Shipped:'Отправленный',
    Delivered:'Доставленный',
    Cancelled:'Отменено',
    Shipping_status:'Статус отправки',
    Users_Report:'Отчет пользователей',
    stock_report:'Отчет о запасах',
    TotalPurchases:'Всего покупок',
    Total_quotations:'Всего котировок',
    Total_return_sales:'Общий возврат продаж',
    Total_return_purchases:'Всего возвращенных покупок',
    Total_transfers:'Всего переводов',
    Total_adjustments:'Всего корректировок',
    User_report:'Пользовательский отчет',
    Current_stock:'Текущий запас',
    product_name:'наименование товара',
    Total_Customers_Due:'Общая задолженность',
    Total_Suppliers_Due:'Общая задолженность',
    Some_warehouses:'Некоторые склады',
    All_Warehouses:'Все склады',
    Product_Cost:'Стоимость продукта',

    sms_settings:'Настройки смс',
    pos_settings:'Настройки POS',
    payment_gateway:'Платежный шлюз',
    mail_settings:'Настройки почты',
    Nexmo_SMS:'Nexmo SMS',
    TWILIO_SMS:'TWILIO SMS',
    Default_SMS_Gateway:'SMS-шлюз по умолчанию',
    module_settings:'Настройки модуля',
    Module_enabled_success:'Модуль успешно включен',
    Module_Disabled_success:'Модуль успешно отключен',
    update_settings:'Обновить настройки',
    Please_Upload_the_Correct_Module:'Пожалуйста, загрузите правильный модуль',
    Uploaded_Success:'Загружено успешно',
    Customer_details:'Сведения о клиенте',
    Edit_Customer:'Редактировать заказчика',
    Delete_Customer:'Удалить клиента',
    Pay_Due:'Оплатить должный',
    Paying_amount_is_greater_than_Total_Due:'Сумма платежа больше общей суммы платежа',
    Customer_Invoice:'счет-фактура клиента',
    This_Product_Not_For_Selling:'Этот продукт не для продажи',
    Welcome_to_your_Dashboard:'Добро пожаловать в вашу панель инструментов',
    Total_Payable:'Итого к оплате',
    Choose_Sale_Ref:'Выберите номер продажи',
    Please_Select_Sale:'Пожалуйста, выберите Распродажа',
    Sale_Ref:'Продажа Реф.',
    list_product_returns:'список возвратов продукции',
    Qty_return:'Кол-во возврата',
    Choose_Purchase_Ref:'Выберите покупку реф.',
    Purchase_Ref:'покупка реф',
    please_select_purchase:'Пожалуйста, выберите Покупка',
    qty_return_is_greater_than_qty_purchased:'Кол-во возврата больше, чем куплено',
    qty_return_is_greater_than_qty_sold:'Возврат количества больше, чем количество продано',
    Quantity_sold:'Кол-во продано',
    qty_purchased:'Кол-во куплено',
    Sender_Name:'Имя отправителя',
    The_module_must_be_uploaded_as_zip_file:'Модуль должен быть загружен в виде zip-файла',
    Upload_Module:'Выгрузить модуль',
    All_Modules_Installed:'Все модули установлены',
    Current_Version:'Текущая версия',
    You_already_have_the_latest_version:'У вас уже есть последняя версия',
    Update_Available:'Обновления доступны',
    Update_Log:'Журнал обновлений',
    Update_Now:'Обновить сейчас',
    View_Change_Log:'Просмотр журнала изменений',
    invoice_footer:'Нижний колонтитул счета',
    Please_Wait_for_the_update :'ПРИМЕЧАНИЕ. Дождитесь полного завершения обновления. Не рекомендуется закрывать браузер или останавливать процесс обновления.',
    Note_update :'Примечание. Обязательно сделайте резервную копию текущей версии и базы данных перед запуском обновления, чтобы восстановить их в случае ошибки.',
    Time_Zone:'Часовой пояс',
    Payment_note:'отмечает оплату',
    sale_note:'примечания к продаже',
    Total_Sale_Due:'Общая задолженность по продаже',
    Total_Sell_Return_Due:'Общий долг по возврату продаж',
    pay_all_sell_due_at_a_time:'погасить весь долг за один раз',
    pay_all_sell_return_due_at_a_time:'оплатить всю задолженность по возврату продажи за один раз',
    Gross_Profit:'Валовая прибыль',
    pay_all_purchase_due_at_a_time:'погасить всю задолженность по покупке единовременно',
    Delete_Provider:'Удалить провайдера',
    Edit_Provider:'Изменить провайдера',
    Provider_details:'Сведения о поставщике',
    Total_Purchase_Due:'Общий долг покупки',
    Provider_Credit_Note:'Кредитная нота',
    Customer_Credit_Note:'Кредитная нота',
    Sell_Return:'Продать Возврат',
    Purchase_Return:'Возврат покупки',
    Tax_Number:'Налоговый номер',
    Please_add_return_quantity:'Пожалуйста, добавьте количество возврата',
    Return_exist_for_the_Transaction:'Возврат существует для транзакции',
    qty_return_is_greater_than_Quantity_Remaining:'Возврат количества больше, чем оставшееся количество',
    products_refunded_alert:'Любые продукты с количеством, установленным на 0, не будут возвращены',
    pay_all_purchase_return_due_at_a_time:'оплатить всю задолженность по возврату покупки единовременно',
    Total_Purchase_Return_Due:'Общая задолженность по возврату покупки',
    Purchase_return_due:'Задолженность по возврату покупки',
    Sell_return_due:'Продать возврат из-за',
    product_report:'Отчет о продукте',
    product_sales_report:'отчет о продажах продукции',
    Product_purchases_report:'Отчет о покупках продуктов',
    Qty_sold:'Кол-во продано',
    Qty_purchased:'Кол-во куплено',
    Filter_by_warehouse:'Отфильтровать по складу',
    Enable_Print_Invoice:'Распечатать счет автоматически',
    Show_Warehouse:'посмотреть склад',

    credit_card_info: 'Информация о кредитной карте',
    Saved_Credit_Card_Info: 'Сохраненная информация о кредитной карте',
    Credit_card_changed_successfully: 'Кредитная карта успешно изменена',
    notification_template: 'Шаблон уведомления',
    email_notification: 'Уведомление по электронной почте',
    sms_notification: 'Уведомление по SMS',
    Change_product_details: 'Изменить детали продукта',
    edit_tax_and_discount_and_shipping: 'Изменить налог, скидку и доставку',
    Create_Quotation_with_Stock: 'Создать квоту со складом',
    Variant_Name: 'Название варианта',
    Variant_cost: 'Стоимость варианта',
    Variant_price: 'Цена варианта',
    Variant_code: 'Код варианта',
    Please_wait_until_the_product_is_loaded: 'Пожалуйста, подождите, пока продукт загружается',
    Choose_SMS_Gateway: 'Выберите шлюз SMS',
    Notification_Client: 'Уведомление клиента',
    Available_Tags: 'Доступные теги',
    Email_Subject: 'Тема письма',
    Email_body: 'Тело электронного письма',
    Notification_Supplier: 'Уведомление поставщика',
    sms_body: 'Текст SMS',
    sms_templates:'SMS Templates',
    email_templates:'Email Templates',
};