//Language Anglais

export default {
    Receipt:'Receipt',
    Pos_Settings:'Pos Settings (Receipt)',
    Note_to_customer:'Note to customer',
    Show_Note_to_customer:'Show Note to customer',
    Show_barcode:'Show barcode',
    Show_Tax_and_Discount:'Show Tax & Discount & Shipping',
    Show_Customer:'Show Customer',
    Show_Email:'Show Email',
    Show_Phone:'Show Phone',
    Show_Address:'Show Address',
    DefaultLanguage:'Default Language',
    footer:'footer',
    Received_Amount:'Received Amount',
    Paying_Amount:'Paying Amount',
    Change:'Change Return',
    Paying_amount_is_greater_than_Received_amount:'Paying amount is greater than received amount',
    Paying_amount_is_greater_than_Grand_Total:'Paying amount is greater than Grand Total',
    code_must_be_not_exist_already:'code must be not exist already',
    You_will_find_your_backup_on:'You will find your backup on',
    and_save_it_to_your_pc:'and save it to your pc',
    Scan_your_barcode_and_select_the_correct_symbology_below:'Scan your barcode and select the correct symbology below',
    Scan_Search_Product_by_Code_Name:'Scan/Search Product by Code Or Name',
    Paper_size:'Paper size',
    Clear_Cache:'Clear Cache',
    Cache_cleared_successfully:'Cache cleared successfully',
    Failed_to_clear_cache:'Failed to clear cache',
    Scan_Barcode:'Barcode Scanner',
    Please_use_short_name_of_unit:'Please use short name of unit',
    DefaultCustomer:'Default Customer',
    DefaultWarehouse:'Default Warehouse',
    Payment_Gateway:'Payment Gateway',
    SMS_Configuration:'SMS Configuration',
    Gateway:'SMS Gateway',
    Choose_Gateway:'Choose SMS Gateway',
    Send_SMS :'Message sent successfully',
    sms_config_invalid:'wrong sms config invalid',
    Remove_Stripe_Key_Secret:'Delete Stripe API keys',
    credit_card_account_not_available :'Credit card account not available',
    Credit_Card_Info:'Credit Card Info',
    developed_by:'Developed by',
    Unit_already_linked_with_sub_unit:'Unit already linked with sub unit',
    Total_Items_Quantity : 'Total Items & Quantity',
    Value_by_Cost_and_Price : 'Value by Cost and Price',
    Search_this_table: 'Search this table',
    import_products:'Import products',
    Field_optional:'Field optional',
    Download_exemple:'Download example',
    field_must_be_in_csv_format:'Field must be in csv format',
    Successfully_Imported:'Successfully Imported',
    file_size_must_be_less_than_1_mega:'File size must be less than 1 mega',
    Please_follow_the_import_instructions:'Please follow the import instructions',
    must_be_exist:'unit must already be created',
    Import_Customers:'Import Customers',
    Import_Suppliers:'Import Suppliers',
    Recent_Sales : 'Recent Sales',
    Create_Transfer : 'Create Transfer',
    order_products : 'Order items',
    Search_Product_by_Code_Name : 'Search Product by code or name',
    Reports_payments_Purchase_Return:'Reports Purchase Return Payments',
    Reports_payments_Sale_Return:'Reports Sale Return Payments',
    payments_Sales_Return:'Payments Sales Return',
    payments_Purchases_Return:'Payments Purchases Return',
    CreateSaleReturn:'Create Sale Return',
    EditSaleReturn:'Edit Sale Return',
    SalesReturn:'Sales Return',
    CreatePurchaseReturn:'Create Purchase Return',
    EditPurchaseReturn:'Edit Purchase Return',
    PurchasesReturn:'Purchases Return',
    Due:'Due',
    Profit: 'Profit',
    Revenue: 'Revenue',
    Sales_today: 'Today Sales',
    People: 'People',
    Successfully_Created: 'Successfully Created',
    Successfully_Updated: 'Successfully Updated',
    Success: 'Success',
    Failed: 'Failed',
    Warning: 'Warning',
    Please_fill_the_form_correctly: 'Please fill the form correctly',
    Field_is_required: 'This Field is required',
    Error: 'Error!',
    you_are_not_authorized: 'Sorry! you are not authorized.',
    Go_back_to_home: 'Go back to homepage',
    page_not_exist: 'Sorry! The page you were looking for doesn\'t exist.',
    Choose_Status: 'Choose Status',
    Choose_Method: 'Choose Method',
    Choose_Symbology: 'Choose symbology',
    Choose_Category: 'Choose Category',
    Choose_Customer: 'Choose Customer',
    Choose_Supplier: 'Choose Supplier',
    Choose_Unit_Purchase: 'Choose Purchase Unit',
    Choose_Sub_Category: 'Choose SubCategory',
    Choose_Brand: 'Choose Brand',
    Choose_Warehouse: 'Choose Warehouse',
    Choose_Unit_Sale: 'Choose Sale Unit',
    Enter_Product_Cost: 'Enter Product Cost',
    Enter_Stock_alert: 'Enter Stock alert',
    Enter_Quantity: 'Enter Quantity',
    Warehouses_Master: 'Warehouses Master',
    Choose_Unit_Product: 'Choose Product Unit',
    Enter_Product_Price: 'Enter Product Price',
    Enter_Name_Product: 'Enter Name Product',
    Enter_Role_Name: 'Enter Role Name',
    Enter_Role_Description: 'Enter Role Description',
    Enter_name_category: 'Enter category Name',
    Enter_Code_category: 'Enter category Code',
    Enter_Name_Brand: 'Enter Name Brand',
    Enter_Description_Brand: 'Enter Description Brand',
    Enter_Code_Currency: 'Enter Code Currency',
    Enter_name_Currency: 'Enter name Currency',
    Enter_Symbol_Currency: 'Enter Symbol Currency',
    Enter_Name_Unit: 'Enter Unit Name',
    Enter_ShortName_Unit: 'Enter shortname Unit',
    Choose_Base_Unit: 'Choose Base Unit',
    Choose_Operator: 'Choose Operator',
    Enter_Operation_Value: 'Enter Operation Value',
    Enter_Name_Warehouse: 'Enter Warehouse Name',
    Enter_Phone_Warehouse: 'Enter Warehouse Phone',
    Enter_Country_Warehouse: 'Enter Warehouse Country',
    Enter_City_Warehouse: 'Enter Warehouse City',
    Enter_Email_Warehouse: 'Enter Warehouse Email',
    Enter_ZipCode_Warehouse: 'Enter Warehouse Zip Code',
    Choose_Currency: 'Choose Currency',
    Thank_you_for_your_business: 'Thank you for shopping with us . Please come again',
    Cancel: 'Cancel',
    New_Customer: 'New Customer',
    Incorrect_Login: 'Incorrect Login',
    Successfully_Logged_In: 'Successfully Logged In',
    This_user_not_active: 'This user not active',
    SignIn: 'Sign In',
    Create_an_account: 'Create an account',
    Forgot_Password: 'Forgot Password ?',
    Email_Address: 'Email Address',
    SignUp: 'Sign Up',
    Already_have_an_account: 'Already have an account ?',
    Reset_Password: 'Reset Password',
    Failed_to_authenticate_on_SMTP_server: 'Failed to authenticate on SMTP server',
    We_cant_find_a_user_with_that_email_addres: 'We can\'t find a user with that email addres',
    We_have_emailed_your_password_reset_link: 'We have e-mailed your password reset link',
    Please_fill_the_Email_Adress: 'Please fill the Email Address',
    Confirm_password: 'Confirm password',
    Your_Password_has_been_changed: 'Your Password has been changed',
    The_password_confirmation_does_not_match: 'The password confirmation does not match',
    This_password_reset_token_is_invalid: 'This password reset token is invalid',
    Warehouse_report: 'Warehouse report',
    All_Warehouses: 'All Warehouses',
    Expense_List: 'All Expenses',
    Expenses: 'Expenses',
    This_Week_Sales_Purchases: 'This Week Sales & Purchases',
    Top_Selling_Products: 'Top Selling Products',
    View_all: 'View all',
    Payment_Sent_Received: 'Payment Sent & Received',
    Filter: 'Filter',
    Invoice_POS: 'Invoice POS',
    Invoice: 'Invoice',
    Customer_Info: 'Customer Info',
    Company_Info: 'Company Info',
    Invoice_Info: 'Invoice Info',
    Order_Summary: 'Order Summary',
    Quote_Info: 'Quotation Info',
    Del: 'Delete',
    SuppliersPaiementsReport: 'Suppliers Payments Report',
    Purchase_Info: 'Purchase Info',
    Supplier_Info: 'Supplier Info',
    Return_Info: 'Info of Return',
    Expense_Category: 'Expense Category',
    Create_Expense: 'Create Expense',
    Details: 'Details',
    Discount_Method: 'Discount Type',
    Net_Unit_Cost: 'Net Unit Cost',
    Net_Unit_Price: 'Net Unit Price',
    Edit_Expense: 'Edit Expense',
    All_Brand: 'All Brand',
    All_Category: 'All Category',
    ListExpenses: 'All Expenses',
    Create_Permission: 'Create Permission',
    Edit_Permission: 'Edit Permission',
    Reports_payments_Sales: 'Reports payments Sales',
    Reports_payments_Purchases: 'Reports payments Purchases',
    Reports_payments_Return_Customers: 'Payments Return Customers',
    Reports_payments_Return_Suppliers: 'Payments Return Suppliers',
    Expense_Deleted: 'This Expense has been deleted',
    Expense_Updated: 'This Expense has been Updated',
    Expense_Created: 'This Expense has been Created',
    DemoVersion: 'You cannot do this in the demo version',
    OrderStatistics: 'Sales Statistics',
    AlreadyAdd: 'This Product Already Added !!',
    AddProductToList: 'Please Add Product To List !!',
    AddQuantity: 'Please Add quantity of product !!',
    InvalidData: 'Invalid Data !!',
    LowStock: 'Quantity exceeds quantity available in stock',
    WarehouseIdentical: 'The two warehouses cannot be identical !!',
    VariantDuplicate: 'This Variant Is Duplicate !!',
    Filesize: 'File size',
    GenerateBackup: 'Generate Backup',
    BackupDatabase: 'Backup Database',
    Backup: 'Backup',
    Paid: 'Paid',
    Unpaid: 'Unpaid',
    Today: 'Today',
    Income: 'Income',
    Expenses: 'Expenses',
    Sale: 'Sale',
    Actif: 'Active',
    Inactif: 'Inactive',
    Customers: 'Customers',
    Phone: 'Phone',
    SearchByPhone: 'Search by Phone',
    Suppliers: 'Suppliers',
    Quotations: 'Quotations',
    Sales: 'Sales',
    Purchases: 'Purchases',
    Returns: 'Return',
    Settings: 'Settings',
    SystemSettings: 'System Settings',
    Users: 'Users',
    GroupPermissions: 'Group Permissions',
    Currencies: 'Currency',
    Warehouses: 'Warehouse',
    Units: 'Unit',
    UnitsPrchases: 'Purchases Units',
    UnitsSales: 'Sales Units',
    Reports: 'Reports',
    PaymentsReport: 'Payments Report',
    PaymentsPurchases: 'Payments Purchases',
    PaymentsSales: 'Payments Sales',
    ProfitandLoss: 'Profit and Loss',
    WarehouseStockChart: 'Warehouse Stock Chart',
    SalesReport: 'Sale Report',
    PurchasesReport: 'Purchase Report',
    CustomersReport: 'Customer Report',
    SuppliersReport: 'Supplier Report',
    SupplierReport: 'Supplier Report',
    DailySalesData: 'Daily Sales Data',
    DailyPurchasesData: 'Daily Purchases Data',
    Dernièrescinqrecords: 'Last five records',
    Filters: 'Filters',
    date: 'Date',
    Reference: 'Reference',
    Supplier: 'Supplier',
    PaymentStatus: 'Payment Status',
    Customer: 'Customer',
    CustomerCode: 'Customer Code',
    Status: 'Status',
    SupplierCode: 'Supplier Code',
    Categorie: 'Category',
    Categories: 'Category',
    StockTransfers: 'Transfer',
    StockManagement: 'Stock Management',
    dashboard: 'Dashboard',
    Products: 'Products',
    productsList: 'All Products',
    ProductManagement: 'Product Management',
    ProductQuantityAlerts: 'Product Quantity Alerts',
    CodeProduct: 'Code Product',
    ProductTax: 'Product Tax',
    SubCategorie: 'Sub Category',
    Name_product: 'Name',
    StockAlert: 'Stock Alert',
    warehouse: 'Warehouse',
    Tax: 'Tax',
    BuyingPrice: 'Buying price',
    SellPrice: 'Sell price',
    Quantity: 'Quantity',
    UnitSale: 'Sale Unit',
    UnitPurchase: 'Purchase Unit',
    ManagementCurrencies: 'Currency Management',
    CurrencyCode: 'Currency Code',
    CurrencyName: 'Currency Name',
    Symbol: 'Symbol',
    All: 'All',
    EditProduct: 'Edit Product',
    SearchByCode: 'Search by Code',
    SearchByName: 'Search by Name',
    ProductDetails: 'Product Details',
    CustomerName: 'Customer Name',
    CustomerManagement: 'Customer Management',
    Add: 'Create',
    add: 'Create',
    Edit: 'Edit',
    Close: 'Close',
    PleaseSelect: 'Please Select',
    Action: 'Action',
    Email: 'Email',
    EditCustomer: 'Edit Customer',
    AddCustomer: 'Create Customer',
    Country: 'Country',
    City: 'City',
    Adress: 'Address',
    CustomerDetails: 'Customer Details',
    CustomersList: 'Customers',
    SupplierCode: 'Supplier Code',
    SupplierName: 'Supplier Name',
    SuppliersManagement: 'Suppliers Management',
    SupplierDetails: 'Supplier Details',
    QuotationsManagement: 'Quotations Management',
    SubTotal: 'Subtotal',
    MontantReste: 'Amount left',
    complete: 'Completed',
    EnAttendant: 'Pending',
    Recu: 'Received',
    partial: 'Partial',
    Retournee: 'Return',
    DetailQuote: 'Detail Quotation',
    EditQuote: 'Edit Quotation',
    CreateSale: 'Create Sale',
    DownloadPdf: 'Download Pdf',
    QuoteEmail: 'Send Quotation on Email',
    DeleteQuote: 'Delete Quotation',
    AddQuote: 'Create Quotation',
    SelectProduct: 'Select Product',
    ProductCodeName: 'Product (Code - Name)',
    Price: 'Price',
    CurrentStock: 'Stock',
    Total: 'Grand Total',
    Num: 'N°',
    Unitcost: 'Unit cost',
    to: 'To',
    Subject: 'Subject',
    Message: 'Message',
    EmailCustomer: 'Email Customer',
    Sent: 'Send',
    Quote: 'Quotation',
    Hello: 'Hello',
    AttachmentQuote: 'Please find the attachment for your Quotation',
    AddProducts: 'Add Products to the Order List',
    SelectWarehouse: 'Please Select warehouse',
    SelectCustomer: 'please Select Customer',
    SalesManagement: 'Sales Management',
    Balance: 'Balance',
    QtyBack: 'Qty Back',
    TotalReturn: 'Total Return',
    Amount: 'Amount',
    SaleDetail: 'Sale Detail',
    EditSale: 'Edit Sale',
    AddSale: 'Create Sale',
    ShowPayment: 'Show Payments',
    AddPayment: 'Create Payment',
    EditPayment: 'Edit Payment',
    EmailSale: 'Send Sale on Email',
    DeleteSale: 'Delete Sale',
    ModePaiement: 'Paid by',
    Paymentchoice: 'Payment choice',
    Note: 'Note',
    PaymentComplete: 'Payment complete!',
    PurchasesManagement: 'Purchases Management',
    Ordered: 'Ordered',
    DeletePurchase: 'Delete Purchase',
    EmailPurchase: 'Send Purchase on Email',
    EditPurchase: 'Edit Purchase',
    PurchaseDetail: 'Purchase Detail',
    AddPurchase: 'Create Purchase',
    EmailSupplier: 'Supplier Email',
    PurchaseInvoice: 'Purchases payments',
    PurchasesInvoicesData: 'Purchases payments data',
    SalesInvoice: 'Sales payments',
    SalesInvoicesData: 'Sales payments data',
    UserManagement: 'Users management',
    Firstname: 'First name',
    lastname: 'Last name',
    username: 'Username',
    password: 'Password',
    Newpassword: 'New password',
    ChangeAvatar: 'Change Avatar',
    LeaveBlank: 'Please leave this field blank if you haven\'t changed it',
    type: 'Type',
    UserPermissions: 'Users Permissions',
    RoleName: 'Role',
    RoleDescription: 'Role Description',
    AddPermissions: 'Create Permissions',
    View: 'View',
    Del: 'Delete',
    NewAdjustement: 'New Adjustement',
    EditAdjustement: 'Edit Adjustement',
    CannotSubstraction: 'You cannot subtraction products which have stock 0',
    Addition: 'Addition',
    Subtraction: 'Subtraction',
    profil: 'Profile',
    logout: 'Logout',
    PurchaseAlreadyPaid: 'You cannot modify because this Purchase already paid',
    SaleAlreadyPaid: 'You cannot modify because this Sale already paid',
    ReturnAlreadyPaid: 'You cannot modify because this Return already paid',
    QuoteAlready: 'This quote already has generate sale',
    AddProduct: 'Create product',
    QuotationComplete: 'This Quotation Complete',
    SiteConfiguration: 'Site Configuration',
    Language: 'Language',
    DefaultCurrency: 'Default Currency',
    LoginCaptcha: 'Login Captcha',
    DefaultEmail: 'Default Email',
    SiteName: 'Site Name',
    ChangeLogo: 'Change Logo',
    SMTPConfiguration: 'SMTP Configuration',
    HOST: 'MAIL_HOST',
    PORT: 'MAIL_PORT',
    encryption: 'MAIL_ENCRYPTION',
    SMTPIncorrect: 'SMTP Configuration Incorrect',
    PaymentsReturns: 'Payments Returns',
    ReturnsInvoices: 'Returns Invoices',
    ReturnsInvoicesData: 'Returns Invoices Data',
    ShowAll: 'View all records of all Users',
    Discount: 'Discount',
    OrderTax: 'Order Tax',
    Shipping: 'Shipping',
    CompanyName: 'Company Name',
    CompanyPhone: 'Company Phone',
    CompanyAdress: 'Company Address',
    Code: 'Code',
    image: 'Image',
    Printbarcode: 'Print Labels',
    ReturnsCustomers: 'Returns Customer',
    ReturnsSuppliers: 'Returns Supplier',
    FactureReturnCustomers: 'Return Customer Invoice',
    FactureReturnSuppliers: 'Return Supplier Invoice',
    NodataAvailable: 'No data Available',
    ProductImage: 'Product Image',
    Barcode: 'Barcode',
    pointofsales: 'Point of Sales',
    CustomUpload: 'Custom Upload',
    pointofsaleManagement: 'Point of Sale Management',
    Adjustment: 'Adjustment',
    Updat: 'Update',
    Reset: 'Reset',
    print: 'Print',
    SearchByEmail: 'Search By Email',
    ChooseProduct: 'Choose Product',
    Qty: 'Qty',
    Items: 'Items',
    AmountHT: 'Amount HT',
    AmountTTC: 'Amount TTC',
    PleaseSelectSupplier: 'Please Select Supplier',
    PleaseSelectStatut: 'Please Select Status',
    PayeBy: 'Paid By',
    ChooseWarehouse: 'Choose Warehouse',
    payNow: 'Pay Now',
    ListofCategory: 'List of Category',
    Description: 'Description',
    submit: 'Submit',
    ProblemCreatingThisInvoice: 'There was a problem creating this Invoice. Please try again',
    ProblemPayment: 'There was a problem Payment. Please try again.',
    IncomeExpenses: 'Income & Expenses',
    dailySalesPurchases: 'Daily Sales & Purchases',
    ProductsExpired: 'Products Expired',
    ListofBrand: 'Brands',
    CreateAdjustment: 'Create Adjustment',
    Afewwords: 'A few words ...',
    UserImage: 'User Image',
    UpdateProduct: 'Update Product',
    Brand: 'Brand',
    BarcodeSymbology: 'Barcode Symbology',
    ProductCost: 'Product Cost',
    ProductPrice: 'Product Price',
    UnitProduct: 'Product Unit',
    TaxMethod: 'Tax Type',
    MultipleImage: 'Multiple Image',
    ProductHasMultiVariants: 'This Product Has Multi Variants',
    ProductHasPromotion: 'Product Has Promotion',
    PromotionStart: 'Promotion Start',
    PromotionEnd: 'Promotion End',
    PromotionPrice: 'Promotion Price',
    Price: 'Price',
    Cost: 'Cost',
    Unit: 'Unit',
    ProductVariant: 'Product Variant',
    Variant: 'Variant',
    UnitPrice: 'Unit Price',
    CreateReturnCustomer: 'Create Return Customer',
    EditReturnCustomer: 'Edit Return Customer',
    CreateReturnSupplier: 'Create Return Supplier',
    Documentation: 'Doc',
    EditReturnSupplier: 'Edit Return Supplier',
    FromWarehouse: 'From Warehouse',
    ToWarehouse: 'To Warehouse',
    EditTransfer: 'Edit Transfer',
    TransferDetail: 'Transfer Detail',
    Pending: 'Pending',
    Received: 'Received',
    Ordered: 'Ordered',
    PermissionsManager: 'Permissions Management',
    BrandManager: 'Brand',
    BrandImage: 'Brand Image',
    BrandName: 'Brand Name',
    BrandDescription: 'Brand Description',
    BaseUnit: 'Base Unit',
    ManagerUnits: 'Units Management',
    OperationValue: 'Operation Value',
    Operator: 'Operator',
    Top5Products: 'Top 5 Products',
    Last5Sales: 'Last 5 Sales',
    ListAdjustments: 'All Adjustments',
    ListTransfers: 'All Transfers',
    CreateTransfer: 'Create Transfer',
    OrdersManager: 'Orders Management',
    ListQuotations: 'All Quotations',
    ListPurchases: 'All Purchases',
    ListSales: 'All Sales',
    ListReturns: 'All Returns',
    PeopleManager: 'People Management',

    //sweet Alert
    Delete: {
        Title: 'Are you sure?',
        Text: 'You won\'t be able to revert this!',
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'Cancel',
        Deleted: 'Deleted!',
        Failed: 'Failed!',
        Therewassomethingwronge: 'There was something wronge',
        CustomerDeleted: 'This Client has been deleted.',
        SupplierDeleted: 'This Supplier has been deleted.',
        QuoteDeleted: 'This Quotation has been deleted.',
        SaleDeleted: 'This Sale has been deleted.',
        PaymentDeleted: 'This Payment has been deleted.',
        PurchaseDeleted: 'This Purchase has been deleted.',
        ReturnDeleted: 'This Return has been deleted.',
        ProductDeleted: 'This Product has been deleted.',
        ClientError: 'This Client already linked with other Operation',
        ProviderError: 'This Supplier already linked with other Operation',
        UserDeleted: 'This User has been deleted.',
        UnitDeleted: 'This Unit has been deleted.',
        RoleDeleted: 'This Role has been deleted.',
        TaxeDeleted: 'This Tax has been deleted.',
        SubCatDeleted: 'This Sub Category has been deleted.',
        CatDeleted: 'This Category has been deleted.',
        WarehouseDeleted: 'This Warehouse has been deleted.',
        AlreadyLinked: 'This product already linked with other Operation',
        AdjustDeleted: 'This Adjustement has been deleted.',
        TitleCurrency: 'This Currency has been deleted.',
        TitleTransfer: 'The Transfer has been removed successfully',
        BackupDeleted: 'Backup has been removed successfully',
        TitleBrand: 'This Brand has been deleted',


    },
    Update: {
        TitleBrand: 'This Brand has been Updated',
        TitleProfile: 'Your Profile Updated in successfully',
        TitleAdjust: 'Adjustement Updated in successfully',
        TitleRole: 'Role Updated in successfully',
        TitleUnit: 'Unit Updated in successfully',
        TitleUser: 'User Updated in successfully',
        TitleCustomer: 'Customer Updated in successfully',
        TitleQuote: 'Quotation Updated in successfully',
        TitleSale: 'Sale Updated in successfully',
        TitlePayment: 'Payment Updated in successfully',
        TitlePurchase: 'Purchase Updated in successfully',
        TitleReturn: 'Return Updated in successfully',
        TitleProduct: 'Product Updated in successfully',
        TitleSupplier: 'Supplier Updated in successfully',
        TitleTaxe: 'Tax Updated in successfully',
        TitleCat: 'Category Updated in successfully',
        TitleWarhouse: 'Warhouse Updated in successfully',
        TitleSetting: 'Settings Updated in successfully',
        TitleCurrency: 'Currency Updated in successfully',
        TitleTransfer: 'Transfer Updated in successfully',
    },

    Create: {
        TitleBrand: 'This Brand has been Created',
        TitleRole: 'Role Created in successfully',
        TitleUnit: 'Unit Created in successfully',
        TitleUser: 'User Created in successfully',
        TitleCustomer: 'Customer Created in successfully',
        TitleQuote: 'Quotation Created in successfully',
        TitleSale: 'Sale Created in successfully',
        TitlePayment: 'Payment Created in successfully',
        TitlePurchase: 'Purchase Created in successfully',
        TitleReturn: 'Return Created in successfully',
        TitleProduct: 'Product Created in successfully',
        TitleSupplier: 'Supplier Created in successfully',
        TitleTaxe: 'Tax Created in successfully',
        TitleCat: 'Category Created in successfully',
        TitleWarhouse: 'Warehouse Created in successfully',
        TitleAdjust: 'Adjustement Created in successfully',
        TitleCurrency: 'Currency Created in successfully',
        TitleTransfer: 'Transfer Created in successfully',
    },
    Send: {
        TitleEmail: 'Email Send in successfully',
    },
    return: {
        TitleSale: 'This sale already linked with a Return!',
    },
    ReturnManagement: 'Return Management',
    ReturnDetail: 'Return Detail',
    EditReturn: 'Edit Return',
    AddReturn: 'Create Return',
    EmailReturn: 'Send Return on Email',
    DeleteReturn: 'Delete Return',
    Retoursurcharge: 'Return Surcharge',
    Laivrison: 'Delivery',
    SelectSale: 'Select Sale',
    ZeroPardefault: 'You can delete the item or set the quantity returned to zero if it is not returned',
    Return: 'Return',
    Purchase: 'Purchase',
    TotalSales: 'Total Sales',
    TotalPurchases: 'Total Purchases',
    TotalReturns: 'Total Returns',
    PaiementsNet: 'Payments Net',
    PaiementsSent: 'Payments Sent',
    PaiementsReceived: 'Payments Received',
    Recieved: 'Recieved',
    Sent: 'Sent',
    ProductQuantityAlerts: 'Product Quantity Alerts',
    ProductCode: 'Code',
    ProductName: 'Product',
    AlertQuantity: 'Alert Quantity',
    WarehouseStockChart: 'Warehouse Stock Chart',
    TotalProducts: 'Total Products',
    TotalQuantity: 'Total Quantity',
    TopCustomers: 'Top 5 Customers',
    TotalAmount: 'Total Amount',
    TotalPaid: 'Total Paid',
    CustomerSalesReport: 'Customer Sales Report',
    CustomerPaiementsReport: 'Customer Payments Report',
    CustomerQuotationsReport: 'Customer Quotations Report',
    Payments: 'Payments',
    TopSuppliers: 'Top 5 Suppliers',
    SupplierPurchasesReport: 'Supplier Purchases Report',
    SupplierPaiementsReport: 'Supplier Payments Report',
    Name: 'Name',
    Code: 'Code',
    ManagementWarehouse: 'Warehouse Management',
    ZipCode: 'Zip Code',
    managementCategories: 'Categories management',
    Codecategorie: 'Category Code',
    Namecategorie: 'Category Name',
    Parentcategorie: 'Parent category',
    managementTax: 'Tax management',
    TaxName: 'Tax Name',
    TaxRate: 'Tax Rate',
    managementUnitPurchases: 'Purchases Unit',
    managementUnitSales: 'Sales Unit',
    ShortName: 'Short Name',
    PleaseSelectThesebeforeaddinganyproduct: 'Please Select These before adding any product',
    StockAdjustement: 'Adjustment',
    PleaseSelectWarehouse: 'Please Select warehouse before choose any product',
    StockTransfer: 'Stock Transfer',
    SelectPeriod: 'Select Period',
    ThisYear: 'This Year',
    ThisToday: 'This Today',
    ThisMonth: 'This Month',
    ThisWeek: 'This Week',
    AdjustmentDetail: 'Adjustment Detail',
    ActivateUser: 'This User Has been Activated',
    DisActivateUser: 'This User Has been Deactivated',
    NotFound: 'Page not found.',
    oops: 'Oops! Page not found.',
    couldNotFind: 'We could not find the page you were looking for.Meanwhile, you may',
    ReturnDashboard: 'Return to dashboard',

    //hrm module
    hrm:'HRM',
    Employees:'Employees',
    Attendance:'Attendance',
    Leave_request:'Leave Request',
    Leave_type:'Leave Type',
    Company:'Company',
    Departments:'Departments',
    Designations:'Designations',
    Office_Shift:'Office Shift',
    Holidays:'Holidays',
    Enter_Company_Name:'Enter company name',
    Enter_email_address:'Enter email address',
    Enter_Company_Phone:'Enter company phone',
    Enter_Company_Country:'Enter company country',
    Created_in_successfully:'Created in successfully',
    Updated_in_successfully:'Updated in successfully',
    Deleted_in_successfully:'Deleted in successfully',
    department:'Department',
    Enter_Department_Name:'Enter department name',
    Choose_Company:'Choose Company',
    Department_Head:'Department Head',
    Choose_Department_Head:'Choose Department Head',
    Enter_Shift_name:'Enter Shift name',
    Monday_In:'Monday In',
    Monday_Out:'Monday Out',
    Tuesday_In:'Tuesday In',
    tuesday_out:'tuesday Out',
    wednesday_in:'Wednesday In',
    wednesday_out:'Wednesday Out',
    thursday_in:'Thursday In',
    thursday_out:'Thursday Out',
    friday_in:'Friday In',
    friday_out:'Friday Out',
    saturday_in:'Saturday In',
    saturday_out:'Saturday Out',
    sunday_in:'Sunday In',
    sunday_out:'Sunday Out',
    Holiday:'Holiday',
    Enter_title:'Enter title',
    title:'title',
    start_date:'Start date',
    Enter_Start_date:'Enter start date',
    Finish_Date:'Finish date',
    Enter_Finish_date:'Enter finish date',
    Please_provide_any_details:'Please provide any details',
    Attendances:'Attendances',
    Enter_Attendance_date:'Enter attendance date',
    Time_In:'Time In',
    Time_Out:'Time Out',
    Choose_Employee:'Choose Employee',
    Employee:'Employee',
    Work_Duration:'Work Duration',
    remaining_leaves_are_insufficient:'Remaining leaves are insufficient',
    Leave_Type:'Leave Type',
    Days:'Days',
    Department:'Department',
    Choose_leave_type:'Choose leave type',
    Choose_status:'Choose status',
    Leave_Reason:'Leave Reason',
    Enter_Reason_Leave:'Enter Reason Leave',
    Add_Employee:'Add Employee',
    FirstName:'First Name',
    Enter_FirstName:'Enter First Name',
    LastName:'Last Name',
    Enter_LastName:'Enter Last Name',
    Gender:'Gender',
    Choose_Gender:'Choose Gender',
    Enter_Birth_date:'Enter birth date',
    Birth_date:'Birth date',
    Enter_Country:'Enter Country',
    Enter_Phone_Number:'Enter Phone Number',
    joining_date:'Joining date',
    Enter_joining_date:'Enter joining date',
    Choose_Designation:'Choose Designation',
    Designation:'Designation',
    Office_Shift:'Office Shift',
    Choose_Office_Shift:'Choose Office Shift',
    Enter_Leaving_Date:'Enter Leaving Date',
    Leaving_Date:'Leaving Date',
    Annual_Leave:'Annual Leave',
    Enter_Annual_Leave:'Enter Annual Leave',
    Remaining_leave:'Remaining leave',
    Employee_Details:'Employee Details',
    Basic_Information:'Basic Information',
    Family_status:'Family Status',
    Choose_Family_status:'Choose Family status',
    Employment_type:'Employment type',
    Select_Employment_type:'Select Employment type',
    Enter_City:'Enter City',
    Province:'Province',
    Enter_Province:'Enter Province',
    Enter_Address:'Enter Address',
    Enter_Zip_code:'Enter Zip code',
    Zip_code:'Zip code',
    Hourly_rate:'Hourly rate',
    Enter_Hourly_rate:'Enter Hourly rate',
    Basic_salary:'Basic salary',
    Enter_Basic_salary:'Enter Basic salary',
    Social_Media:'Social Media',
    Skype:'Skype',
    Enter_Skype:'Enter Skype',
    Facebook:'Facebook',
    Enter_Facebook:'Enter Facebook',
    WhatsApp:'WhatsApp',
    Enter_WhatsApp:'Enter WhatsApp',
    LinkedIn:'LinkedIn',
    Enter_LinkedIn:'Enter LinkedIn',
    Twitter:'Twitter',
    Enter_Twitter:'Enter Twitter',
    Experiences:'Experiences',
    bank_account:'bank account',
    Company_Name:'Company Name',
    Location:'Location',
    Enter_location:'Enter location',
    Enter_Description:'Enter Description',
    Bank_Name:'Bank Name',
    Enter_Bank_Name:'Enter Bank Name',
    Bank_Branch:'Bank Branch',
    Enter_Bank_Branch:'Enter Bank Branch',
    Bank_Number:'Bank Number',
    Enter_Bank_Number:'Enter Bank Number',
    Assigned_warehouses:'Access warehouses',
    Top_customers:'Best customers',
    Attachment:'Attachment',
    view_employee:'view employee',
    edit_employee:'Edit employee',
    delete_employee:'Delete employee',
    Created_by : 'Added by',
    Add_product_IMEI_Serial_number:'Add product IMEI/Serial Number',
    Product_Has_Imei_Serial_number:'Product Has Imei/Serial Number',
    IMEI_SN:'IMEI/SN',
    Shipments:'Shipments',
    delivered_to:'Delivered To',
    shipment_ref:'Shipment Ref',
    sale_ref:'Sale Ref',
    Edit_Shipping:'Edit Shipping',
    Packed:'Packed',
    Shipped:'Shipped',
    Delivered:'Delivered',
    Cancelled:'Cancelled',
    Shipping_status:'Shipping Status',
    Users_Report:'Users Report',
    stock_report:'Stock Report',
    TotalPurchases:'Total Purchases',
    Total_quotations:'Total Quotations',
    Total_return_sales:'Total return sales',
    Total_return_purchases:'Total return purchases',
    Total_transfers:'Total transfers',
    Total_adjustments:'Total adjustments',
    User_report:'User Report',
    Current_stock:'Current Stock',
    product_name:'Product Name',
    Total_Customers_Due:'Total Debt',
    Total_Suppliers_Due:'Total Debt',
    Some_warehouses:'Some Warehouses',
    All_Warehouses:'All Warehouses',
    Product_Cost:'Product Cost',
    sms_settings:'Sms Settings',
    pos_settings:'POS Settings',
    payment_gateway:'Payment Gateway',
    mail_settings:'Mail Settings',
    Nexmo_SMS:'Nexmo SMS',
    TWILIO_SMS:'TWILIO SMS',
    Default_SMS_Gateway:'Default SMS Gateway',
    module_settings:'Module Settings',
    Module_enabled_success:'Module enabled successfully',
    Module_Disabled_success:'Module Disabled successfully',
    update_settings:'Upgrade',
    Please_Upload_the_Correct_Module:'Please Upload the Correct Module',
    Uploaded_Success:'Uploaded in successfully',
    Customer_details:'Customer Details',
    Edit_Customer:'Edit Customer',
    Delete_Customer:'Delete Customer',
    Pay_Due:'Pay Due',
    Paying_amount_is_greater_than_Total_Due:'Paying amount is greater than Total Due',
    Customer_Invoice:'Customer Invoice',
    This_Product_Not_For_Selling:'This Product Not For Selling',
    Welcome_to_your_Dashboard:'Welcome to your Dashboard',
    Total_Payable:'Total Payable',
    Choose_Sale_Ref:'Choose Sale Ref',
    Please_Select_Sale:'Please Select Sale',
    Sale_Ref:'Sale Ref',
    list_product_returns:'list product returns',
    Qty_return:'Qty return',
    Choose_Purchase_Ref:'Choose Purchase Ref',
    Purchase_Ref:'Purchase Ref',
    please_select_purchase:'Please Select Purchase',
    qty_return_is_greater_than_qty_purchased:'Qty return is greater than Qty purchased',
    qty_return_is_greater_than_qty_sold:'Qty return is greater than Qty sold',
    Quantity_sold:'Qty sold',
    qty_purchased:'Qty purchased',
    Sender_Name:'Sender Name',
    The_module_must_be_uploaded_as_zip_file:'The module must be uploaded as zip file',
    Upload_Module:'Upload Module',
    All_Modules_Installed:'All Modules Installed',
    Current_Version:'Current Version',
    You_already_have_the_latest_version:'You already have the latest version',
    Update_Available:'Update Available',
    Update_Log:'Update Log',
    Update_Now:'Update Now',
    View_Change_Log:'View Change Log',
    Please_Wait_for_the_update :'NOTE:  Please Wait for the update to finish completely. It is not recommended to close the browser or stop a process Upgrade',
    Note_update :'Note : Make sure you backup your Current version & database before you run the Upgrade , To restore it if there is an error',
    invoice_footer:'Invoice footer',
    Time_Zone:'Time Zone',
    Payment_note:'Payment notes',
    sale_note:'Sale notes',
    Total_Sale_Due:'Total Sale Due',
    Total_Sell_Return_Due:'Total Sell Return Due',
    pay_all_sell_due_at_a_time:'pay all sell due at a time',
    pay_all_sell_return_due_at_a_time:'pay all sell return due at a time',
    Gross_Profit:'Gross Profit',
    pay_all_purchase_due_at_a_time:'pay all purchase due at a time',
    Delete_Provider:'Delete Provider',
    Edit_Provider:'Edit Provider',
    Provider_details:'Provider details',
    Total_Purchase_Due:'Total Purchase Due',
    Provider_Credit_Note:'Provider Credit Note',
    Customer_Credit_Note:'Customer Credit Note',
    Sell_Return:'Sell Return',
    Purchase_Return:'Purchase Return',
    Tax_Number:'Tax Number',
    Please_add_return_quantity:'Please add return quantity',
    Return_exist_for_the_Transaction:'Return exist for the Transaction',
    qty_return_is_greater_than_Quantity_Remaining:'Qty return is greater than Quantity Remaining',
    products_refunded_alert:'Any products with a quantity set to 0 won\'t be refunded',
    pay_all_purchase_return_due_at_a_time:'pay all purchase return due at a time',
    Total_Purchase_Return_Due:'Total Purchase Return Due',
    Purchase_return_due:'Purchase return due',
    Sell_return_due:'Sell return due',
    product_report:'Product report',
    product_sales_report:'Product sales report',
    Product_purchases_report:'Product purchases report',
    Qty_sold:'Qty sold',
    Qty_purchased:'Qty purchased',
    Filter_by_warehouse:'Filter by warehouse',
    Enable_Print_Invoice:'Print Invoice automatically',
    Show_Warehouse:'Show warehouse',

    credit_card_info:'Credit card info',
    Saved_Credit_Card_Info:'Saved Credit Card Info',
    Credit_card_changed_successfully:'Credit card changed successfully',
    notification_template:'Notification Template',
    email_notification:'Email notification',
    sms_notification:'SMS notification',
    Change_product_details:'Change product details',
    edit_tax_and_discount_and_shipping:'edit tax and discount and shipping',
    Create_Quotation_with_Stock:'Create Quotation with Stock',
    Variant_Name:'Variant Name',
    Variant_cost:'Variant cost',
    Variant_price:'Variant price',
    Variant_code:'Variant code',
    Please_wait_until_the_product_is_loaded:'Please wait until the product is loaded',
    Choose_SMS_Gateway:'Choose SMS Gateway',
    Notification_Client:'Notification Client',
    Available_Tags:'Available Tags',
    Email_Subject:'Email Subject',
    Email_body:'Email body',
    Notification_Supplier:'Notification Supplier',
    sms_body:'SMS body',
    sms_templates:'SMS Templates',
    email_templates:'Email Templates',
};