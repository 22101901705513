
//Language Simplified Chinese

export default {
    Receipt:'收据',
    Pos_Settings:'销售点设置',
    Note_to_customer:'客户须知',
    Show_Note_to_customer:'向客户显示备注',
    Show_barcode:'显示条形码',
    Show_Tax_and_Discount:'显示税收、折扣和运费',
    Show_Customer:'显示客户',
    Show_Email:'显示电子邮件',
    Show_Phone:'显示电话',
    Show_Address:'显示地址',
    DefaultLanguage:'默认语言',
    footer:'页脚',
    Received_Amount:'收到金额',
    Paying_Amount:'支付金额',
    Change:'改变',
    Paying_amount_is_greater_than_Received_amount:'支付金额大于收到金额',
    Paying_amount_is_greater_than_Grand_Total:'支付金额大于总计',
    code_must_be_not_exist_already:'代码必须不存在',
    You_will_find_your_backup_on:'你会发现你的备份',
    and_save_it_to_your_pc:'并将其保存到您的电脑',
    Scan_your_barcode_and_select_the_correct_symbology_below:'扫描您的条形码并在下方选择正确的符号',
    Scan_Search_Product_by_Code_Name:'按代码名称扫描/搜索产品',
    Paper_size:'纸张尺寸',
    Clear_Cache:'清除缓存',
    Cache_cleared_successfully:'缓存清除成功',
    Failed_to_clear_cache:'清除缓存失败',
    Scan_Barcode:'条形码扫描器',
    Please_use_short_name_of_unit:'请使用单位简称',
    DefaultCustomer:'默认客户',
    DefaultWarehouse:'默认仓库',
    Payment_Gateway:'支付网关',
    SMS_Configuration:'短信配置',
    Gateway:'支付网关',
    Choose_Gateway:'选择支付网关',
    Send_SMS :'消息已成功发送',
    sms_config_invalid:'错误的短信配置无效',
    Remove_Stripe_Key_Secret:'删除Stripe API密钥',
    credit_card_account_not_available :'信用卡帐户不可用',
    Credit_Card_Info:'信用卡资料',
    developed_by:'由开发',
    Unit_already_linked_with_sub_unit:'单元已与子单元链接',
    Total_Items_Quantity : '项目总数和数量',
    Value_by_Cost_and_Price : '按成本和价格的价值',
    Search_this_table: '搜索此表',
    import_products:'进口产品',
    Field_optional:'字段可选',
    Download_exemple:'下载范例',
    field_must_be_in_csv_format:'字段必须为csv格式',
    Successfully_Imported:'成功导入',
    file_size_must_be_less_than_1_mega:'档案大小必须小于1兆',
    Please_follow_the_import_instructions:'请遵循导入说明',
    must_be_exist:'单位必须已经创建',
    Import_Customers:'导入客户',
    Import_Suppliers:'进口供应商',
    Recent_Sales : '最近的销售',
    Create_Transfer : '创建转移',
    order_products : '订购物品',
    Search_Product_by_Code_Name : '按代码或名称搜索产品',
    Reports_payments_Purchase_Return:'报告购买退货付款',
    Reports_payments_Sale_Return:'报告销售退货付款',
    payments_Sales_Return:'付款销售退货',
    payments_Purchases_Return:'付款购买退货',
    CreateSaleReturn:'创建销售退货',
    EditSaleReturn:'编辑销售退货',
    SalesReturn:'销售退货',
    CreatePurchaseReturn:'创建采购退货',
    EditPurchaseReturn:'编辑购买退货',
    PurchasesReturn:'采购退货',
    Due:'到期的',
    Profit: '利润',
    Revenue: '收入',
    Sales_today: '今日销量',
    People: '人们',
    Successfully_Created: '成功创建',
    Successfully_Updated: '成功更新',
    Success: '成功',
    Failed: '失败的',
    Warning: '警告',
    Please_fill_the_form_correctly: '请正确填写表格',
    Field_is_required: '必填项',
    Error: '错误!',
    you_are_not_authorized: '对不起！ 您没有权限。',
    Go_back_to_home: '返回首页',
    page_not_exist: '对不起！ 您要查找的页面不存在。',
    Choose_Status: '选择状态',
    Choose_Method: '选择方法',
    Choose_Symbology: '选择符号',
    Choose_Category: '选择类别',
    Choose_Customer: '选择客户',
    Choose_Supplier: '选择供应商',
    Choose_Unit_Purchase: '选择采购单位',
    Choose_Sub_Category: '选择子类别',
    Choose_Brand: '选择品牌',
    Choose_Warehouse: '选择仓库',
    Choose_Unit_Sale: '选择销售单位',
    Enter_Product_Cost: '输入产品成本',
    Enter_Stock_alert: '输入库存警报',
    Enter_Quantity: '输入数量',
    Warehouses_Master: '仓库主控',
    Choose_Unit_Product: '选择产品单位',
    Enter_Product_Price: '输入产品价格',
    Enter_Name_Product: '输入名称产品',
    Enter_Role_Name: '输入角色名称',
    Enter_Role_Description: '输入角色描述',
    Enter_name_category: '输入类别名称',
    Enter_Code_category: '输入类别代码',
    Enter_Name_Brand: '输入名称品牌',
    Enter_Description_Brand: '输入描述品牌',
    Enter_Code_Currency: '输入代码货币',
    Enter_name_Currency: '输入名称货币',
    Enter_Symbol_Currency: '输入符号货币',
    Enter_Name_Unit: '输入单位名称',
    Enter_ShortName_Unit: '输入简称单位',
    Choose_Base_Unit: '选择基本单位',
    Choose_Operator: '选择运营商',
    Enter_Operation_Value: '输入操作值',
    Enter_Name_Warehouse: '输入仓库名称',
    Enter_Phone_Warehouse: '输入仓库电话',
    Enter_Country_Warehouse: '输入仓库国家',
    Enter_City_Warehouse: '进入仓库城市',
    Enter_Email_Warehouse: '输入仓库电子邮件',
    Enter_ZipCode_Warehouse: '输入仓库邮政编码',
    Choose_Currency: '选择货币',
    Thank_you_for_your_business: '感谢您的业务！',
    Cancel: '取消',
    New_Customer: '新客户',
    Incorrect_Login: '登陆错误',
    Successfully_Logged_In: '成功登录',
    This_user_not_active: '该用户不活跃',
    SignIn: '登入',
    Create_an_account: '创建一个帐户',
    Forgot_Password: '忘记密码 ？',
    Email_Address: '电子邮件地址',
    SignUp: '报名',
    Already_have_an_account: '已经有帐号了？',
    Reset_Password: '重设密码',
    Failed_to_authenticate_on_SMTP_server: '无法在SMTP服务器上进行身份验证',
    We_cant_find_a_user_with_that_email_addres: '我们找不到使用该电子邮件地址的用户',
    We_have_emailed_your_password_reset_link: '我们已经通过电子邮件发送了您的密码重置链接',
    Please_fill_the_Email_Adress: '请填写电子邮件地址',
    Confirm_password: '确认密码',
    Your_Password_has_been_changed: '您的密码已被更改',
    The_password_confirmation_does_not_match: '密码确认不匹配',
    This_password_reset_token_is_invalid: '此密码重置令牌无效',
    Warehouse_report: '仓库报告',
    All_Warehouses: '所有仓库',
    Expense_List: '费用清单',
    Expenses: '花费',
    This_Week_Sales_Purchases: '本周买卖',
    Top_Selling_Products: '畅销产品',
    View_all: '查看全部',
    Payment_Sent_Received: '已发送并已收到付款',
    Filter: '过滤',
    Invoice_POS: '发票POS',
    Invoice: '发票',
    Customer_Info: '客户信息',
    Company_Info: '公司介绍',
    Invoice_Info: '发票信息',
    Order_Summary: '订单摘要',
    Quote_Info: '报价信息',
    Del: '删除',
    SuppliersPaiementsReport: '供应商付款报告',
    Purchase_Info: '购买信息',
    Supplier_Info: '供应商信息',
    Return_Info: '退货信息',
    Expense_Category: '费用类别',
    Create_Expense: '创建费用',
    Details: '细节',
    Discount_Method: '优惠方式',
    Net_Unit_Cost: '净单位成本',
    Net_Unit_Price: '净单价',
    Edit_Expense: '编辑费用',
    All_Brand: '所有品牌',
    All_Category: '所有类别',
    ListExpenses: '清单费用',
    Create_Permission: '建立权限',
    Edit_Permission: '编辑权限',
    Reports_payments_Sales: '报告付款销售',
    Reports_payments_Purchases: '报告付款购买',
    Reports_payments_Return_Customers: '报告付款返回客户',
    Reports_payments_Return_Suppliers: '报表付款退货供应商',
    Expense_Deleted: '该费用已被删除',
    Expense_Updated: '此费用已更新',
    Expense_Created: '费用已创建',
    DemoVersion: '您无法在演示版中执行此操作',
    OrderStatistics: '销售统计',
    AlreadyAdd: '该产品已添加！',
    AddProductToList: '请添加产品到列表！',
    AddQuantity: '请添加详细数量！',
    InvalidData: '无效数据 ！！',
    LowStock: '数量超过库存可用数量',
    WarehouseIdentical: '两个仓库不能完全相同！',
    VariantDuplicate: '这个变种是重复的！',
    Filesize: '文件大小',
    GenerateBackup: '产生备份',
    BackupDatabase: '备份资料库',
    Backup: '后备',
    Paid: '已付费',
    Unpaid: '未付',
    Today: '今天',
    Income: '收入',
    Expenses: '花费',
    Sale: '特卖',
    Actif: '活性',
    Inactif: '不活跃',
    Customers: '顾客',
    Phone: '电话',
    SearchByPhone: '通过电话搜索',
    Suppliers: '供应商',
    Quotations: '报价单',
    Sales: '营业额',
    Purchases: '采购',
    Returns: '退货',
    Settings: '设定值',
    SystemSettings: '系统设置',
    Users: '用户数',
    GroupPermissions: '组权限',
    Currencies: '货币',
    Warehouses: '货仓',
    Units: '单位',
    UnitsPrchases: '单位购买',
    UnitsSales: '单位销售',
    Reports: '报告书',
    PaymentsReport: '付款报告',
    PaymentsPurchases: '付款购买',
    PaymentsSales: '付款销售',
    ProfitandLoss: '收益与损失',
    WarehouseStockChart: '仓库库存图',
    SalesReport: '销售报告',
    PurchasesReport: '采购报告',
    CustomersReport: '客户报告',
    SuppliersReport: '供应商报告',
    SupplierReport: '供应商报告',
    DailySalesData: '每日销售数据',
    DailyPurchasesData: '每日购买数据',
    Dernièrescinqrecords: '最近五条记录',
    Filters: '筛选器',
    date: '日期',
    Reference: '参考',
    Supplier: '供应商',
    PaymentStatus: '支付状态',
    Customer: '顾客',
    CustomerCode: '客户代码',
    Status: '状态',
    SupplierCode: '供应商代码',
    Categorie: '类别',
    Categories: '分类目录',
    StockTransfers: '库存转移',
    StockManagement: '库存管理',
    dashboard: '仪表板',
    Products: '产品展示',
    productsList: '产品清单',
    ProductManagement: '产品管理',
    ProductQuantityAlerts: '产品数量警报',
    CodeProduct: '代码产品',
    ProductTax: '产品税',
    SubCategorie: '子类别',
    Name_product: '指定',
    StockAlert: '库存警报',
    warehouse: '仓库',
    Tax: '税',
    BuyingPrice: '买价',
    SellPrice: '卖价',
    Quantity: '数量',
    UnitSale: '单位销售',
    UnitPurchase: '单位购买',
    ManagementCurrencies: '货币管理',
    CurrencyCode: '货币代码',
    CurrencyName: '货币名称',
    Symbol: '符号',
    All: '所有',
    EditProduct: '编辑产品',
    SearchByCode: '按代码搜索',
    SearchByName: '按名称搜索',
    ProductDetails: '产品详情',
    CustomerName: '顾客姓名',
    CustomerManagement: '用户管理',
    Add: '创建',
    add: '创建',
    Edit: '编辑',
    Close: '关',
    PleaseSelect: '请选择',
    Action: '行动',
    Email: '电子邮件',
    EditCustomer: '编辑客户',
    AddCustomer: '建立客户',
    Country: '国家',
    City: '市',
    Adress: '地址',
    CustomerDetails: '顾客信息',
    CustomersList: '客户名单',
    SupplierCode: '供应商代码',
    SupplierName: '供应商名称',
    SuppliersManagement: '供应商管理',
    SupplierDetails: '供应商详细信息',
    QuotationsManagement: '报价管理',
    SubTotal: '小计',
    MontantReste: '剩余金额',
    complete: '已完成',
    EnAttendant: '待定',
    Recu: '已收到',
    partial: '部分的',
    Retournee: '返回',
    DetailQuote: '详细报价',
    EditQuote: '编辑报价',
    CreateSale: '建立销售',
    DownloadPdf: '下载PDF',
    QuoteEmail: '电子邮件发送报价',
    DeleteQuote: '删除报价',
    AddQuote: '创建报价',
    SelectProduct: '选择产品',
    ProductCodeName: '产品（代码-名称）',
    Price: '价钱',
    CurrentStock: '股票',
    Total: '总',
    Num: 'N°',
    Unitcost: '单位成本',
    to: '至',
    Subject: '学科',
    Message: '信息',
    EmailCustomer: '电邮客户',
    Sent: '发送',
    Quote: '报价单',
    Hello: '你好',
    AttachmentQuote: '请找到您的报价附件',
    AddProducts: '将产品添加到订单清单',
    SelectWarehouse: '请选择仓库',
    SelectCustomer: '请选择客户',
    SalesManagement: '销售管理',
    Balance: '平衡',
    QtyBack: '退货数量',
    TotalReturn: '总回报',
    Amount: '量',
    SaleDetail: '销售明细',
    EditSale: '编辑销售',
    AddSale: '建立销售',
    ShowPayment: '显示付款',
    AddPayment: '创建付款',
    EditPayment: '编辑付款',
    EmailSale: '通过电子邮件发送销售',
    DeleteSale: '删除销售',
    ModePaiement: '由...支付',
    Paymentchoice: '付款方式',
    Note: '注意',
    PaymentComplete: '付款完成！',
    PurchasesManagement: '采购管理',
    Ordered: '已订购',
    DeletePurchase: '删除购买',
    EmailPurchase: '通过邮件发送购买',
    EditPurchase: '编辑购买',
    PurchaseDetail: '采购明细',
    AddPurchase: '创建购买',
    EmailSupplier: '供应商邮件',
    PurchaseInvoice: '购买付款',
    PurchasesInvoicesData: '购买付款数据',
    SalesInvoice: '销售付款',
    SalesInvoicesData: '销售付款数据',
    UserManagement: '用户管理',
    Firstname: '名字',
    lastname: '姓',
    username: '用户名',
    password: '密码',
    Newpassword: '新密码',
    ChangeAvatar: '更改头像',
    LeaveBlank: '如果尚未更改，请将该字段留空',
    type: '类型',
    UserPermissions: '用户权限',
    RoleName: '角色',
    RoleDescription: '角色描述',
    AddPermissions: '创建权限',
    View: '视图',
    Del: '删除',
    NewAdjustement: '新调整',
    EditAdjustement: '编辑调整',
    CannotSubstraction: '您不能减去有库存0的产品',
    Addition: '加成',
    Subtraction: '减法',
    profil: '概况',
    logout: '登出',
    PurchaseAlreadyPaid: '您无法修改，因为此购买已付款',
    SaleAlreadyPaid: '您无法修改，因为此销售已经付款',
    ReturnAlreadyPaid: '您无法修改，因为此退货已经支付',
    QuoteAlready: '此报价已产生销售',
    AddProduct: '创建产品',
    QuotationComplete: '报价完成',
    SiteConfiguration: '网站配置',
    Language: '语言',
    DefaultCurrency: '预设货币',
    LoginCaptcha: '登录验证码',
    DefaultEmail: '默认电子邮件',
    SiteName: '网站名称',
    ChangeLogo: '变更标志',
    SMTPConfiguration: 'SMTP配置',
    HOST: '主办',
    PORT: '港口',
    encryption: '加密',
    SMTPIncorrect: 'SMTP配置不正确',
    PaymentsReturns: '付款退货',
    ReturnsInvoices: '退回发票',
    ReturnsInvoicesData: '退回发票数据',
    ShowAll: '显示所有用户的所有记录',
    Discount: '折扣',
    OrderTax: '订单税',
    Shipping: '运输',
    CompanyName: '公司名',
    CompanyPhone: '公司电话',
    CompanyAdress: '公司地址',
    Code: '码',
    image: '图片',
    Printbarcode: '打印条形码',
    ReturnsCustomers: '回头客',
    ReturnsSuppliers: '退货供应商',
    FactureReturnCustomers: '退货客户发票',
    FactureReturnSuppliers: '退货供应商发票',
    NodataAvailable: '没有可用数据',
    ProductImage: '产品图片',
    Barcode: '条码',
    pointofsales: '销售点',
    CustomUpload: '自定义上传',
    pointofsaleManagement: '销售点管理',
    Adjustment: '调整',
    Updat: '更新资料',
    Reset: '重启',
    print: '打印',
    SearchByEmail: '通过电子邮件搜索',
    ChooseProduct: '选择产品',
    Qty: '数量',
    Items: '物品',
    AmountHT: '量',
    AmountTTC: '合计金额',
    PleaseSelectSupplier: '请选择供应商',
    PleaseSelectStatut: '请选择状态',
    PayeBy: '由...支付',
    ChooseWarehouse: '选择仓库',
    payNow: '现在付款',
    ListofCategory: '类别清单',
    Description: '描述',
    submit: '提交',
    ProblemCreatingThisInvoice: '创建此发票时出现问题. Please try again',
    ProblemPayment: '付款有问题。 请再试一次.',
    IncomeExpenses: '收入与支出',
    dailySalesPurchases: '每日销售与购买',
    ProductsExpired: '产品过期',
    ListofBrand: '列出品牌',
    CreateAdjustment: '创建调整',
    Afewwords: '几句话 ...',
    UserImage: '用户图片',
    UpdateProduct: '更新产品',
    Brand: '牌',
    BarcodeSymbology: '条码符号',
    ProductCost: '产品成本',
    ProductPrice: '产品价格',
    UnitProduct: '单位产品',
    TaxMethod: '税法',
    MultipleImage: '多张图片',
    ProductHasMultiVariants: '产品具有多种变体',
    ProductHasPromotion: '产品促销',
    PromotionStart: '促销开始',
    PromotionEnd: '促销结束',
    PromotionPrice: '促销价',
    Price: '价钱',
    Cost: '成本',
    Unit: '单元',
    ProductVariant: '产品变体',
    Variant: '变体',
    UnitPrice: '单价',
    CreateReturnCustomer: '创建退货客户',
    EditReturnCustomer: '编辑退货客户',
    CreateReturnSupplier: '创建退货供应商',
    Documentation: '文献资料',
    EditReturnSupplier: '编辑退货供应商',
    FromWarehouse: '从仓库',
    ToWarehouse: '到仓库',
    EditTransfer: '编辑转移',
    TransferDetail: '转账明细',
    Pending: '待定',
    Received: '已收到',
    Ordered: '已订购',
    PermissionsManager: '权限管理',
    BrandManager: '牌',
    BrandImage: '品牌形象',
    BrandName: '品牌',
    BrandDescription: '品牌描述',
    BaseUnit: '基本单位',
    ManagerUnits: '单位管理',
    OperationValue: '运营价值',
    Operator: '操作员',
    Top5Products: '前五名产品',
    Last5Sales: '最近五次销售',
    ListAdjustments: '清单调整',
    ListTransfers: '清单转移',
    CreateTransfer: '创建转移',
    OrdersManager: '订单管理',
    ListQuotations: '清单报价',
    ListPurchases: '列出购买',
    ListSales: '清单销售',
    ListReturns: '清单退货',
    PeopleManager: '人事管理',



    //sweet Alert
    Delete: {
        Title: 'Are you sure?',
        Text: '您将无法还原它！',
        confirmButtonText: '是的，删除它！',
        cancelButtonText: '取消',
        Deleted: '已删除！',
        Failed: '失败了！',
        Therewassomethingwronge: '出事了',
        CustomerDeleted: '该客户端已被删除。',
        SupplierDeleted: '该供应商已被删除.',
        QuoteDeleted: '此报价已被删除。',
        SaleDeleted: '此销售已被删除。',
        PaymentDeleted: '此付款已被删除。',
        PurchaseDeleted: '此购买已被删除。',
        ReturnDeleted: '此退货已被删除。',
        ProductDeleted: '该产品已被删除。',
        ClientError: '该客户端已经与其他操作链接',
        ProviderError: '该供应商已与其他运营部门链接',
        UserDeleted: '该用户已被删除。',
        UnitDeleted: '该单位已被删除。',
        RoleDeleted: '该角色已被删除。',
        TaxeDeleted: '该税项已被删除.',
        SubCatDeleted: '该子类别已被删除。',
        CatDeleted: '此类别已被删除。',
        WarehouseDeleted: '该仓库已被删除。',
        AlreadyLinked: '该产品已经与其他操作链接',
        AdjustDeleted: '此调整已被删除。',
        TitleCurrency: '该货币已被删除。',
        TitleTransfer: '转移已成功删除',
        BackupDeleted: '备份已成功删除',
        TitleBrand: '该品牌已被删除',

    },
    Update: {
        TitleBrand: '该品牌已更新',
        TitleProfile: '您的个人资料已成功更新',
        TitleAdjust: '调整已成功更新',
        TitleRole: '角色更新成功',
        TitleUnit: '单位更新成功',
        TitleUser: '用户已成功更新',
        TitleCustomer: '客户更新成功',
        TitleQuote: '报价已成功更新',
        TitleSale: '销售已成功更新',
        TitlePayment: '付款已成功更新',
        TitlePurchase: '购买已成功更新',
        TitleReturn: '返回成功更新',
        TitleProduct: '产品更新成功',
        TitleSupplier: '供应商更新成功',
        TitleTaxe: '税务更新成功',
        TitleCat: '分类成功更新',
        TitleWarhouse: '仓库已成功更新',
        TitleSetting: '设置已成功更新',
        TitleCurrency: '货币更新成功',
        TitleTransfer: '转移成功更新',
    },

    Create: {
        TitleBrand: '该品牌已创建',
        TitleRole: '角色创建成功',
        TitleUnit: '单位创建成功',
        TitleUser: '用户创建成功',
        TitleCustomer: '客户创建成功',
        TitleQuote: '报价创建成功',
        TitleSale: '销售成功创建',
        TitlePayment: '付款成功创建',
        TitlePurchase: '购买成功创建',
        TitleReturn: '返回创建成功',
        TitleProduct: '产品创建成功',
        TitleSupplier: '供应商创建成功',
        TitleTaxe: '税收创建成功',
        TitleCat: '分类成功创建',
        TitleWarhouse: '成功创建仓库',
        TitleAdjust: '调整已成功创建',
        TitleCurrency: '货币创建成功',
        TitleTransfer: '转移成功创建',
    },
    Send: {
        TitleEmail: '电子邮件发送成功',
    },
    return: {
        TitleSale: '此销售已经与退货相关！',
    },
    ReturnManagement: '退货管理',
    ReturnDetail: '退货明细',
    EditReturn: '编辑退货',
    AddReturn: '创建退货',
    EmailReturn: '发送邮件退回',
    DeleteReturn: '删除退货',
    Retoursurcharge: '回程附加费',
    Laivrison: '交货',
    SelectSale: '选择销售',
    ZeroPardefault: '您可以删除该项目或将未退回的数量设置为零',
    Return: '返回',
    Purchase: '采购',
    TotalSales: '总销售额',
    TotalPurchases: '总购买',
    TotalReturns: '总回报',
    PaiementsNet: '净付款',
    PaiementsSent: '已付款',
    PaiementsReceived: '付款已收到',
    Recieved: '已收到',
    Sent: '已发送',
    ProductQuantityAlerts: '产品数量警报',
    ProductCode: '码',
    ProductName: '产品',
    AlertQuantity: '警报数量',
    WarehouseStockChart: '仓库库存图',
    TotalProducts: '产品总数',
    TotalQuantity: '总数（量',
    TopCustomers: '前五名客户',
    TotalAmount: '总金额',
    TotalPaid: '总支付',
    CustomerSalesReport: '客户销售报告',
    CustomerPaiementsReport: '客户付款报告',
    CustomerQuotationsReport: '客户报价报告',
    Payments: '付款方式',
    TopSuppliers: '前五名供应商',
    SupplierPurchasesReport: '供应商采购报告',
    SupplierPaiementsReport: '供应商付款报告',
    Name: '名称',
    Code: '码',
    ManagementWarehouse: '仓库管理',
    ZipCode: '邮政编码',
    managementCategories: '分类管理',
    Codecategorie: '代码类别',
    Namecategorie: '名称类别',
    Parentcategorie: '父类别',
    managementTax: '税务管理',
    TaxName: '税名',
    TaxRate: '税率',
    managementUnitPurchases: '采购单位',
    managementUnitSales: '销售单位',
    ShortName: '简称',
    PleaseSelectThesebeforeaddinganyproduct: '请在添加任何产品之前选择这些',
    StockAdjustement: '库存调整',
    PleaseSelectWarehouse: '选择任何产品之前，请先选择仓库',
    StockTransfer: '库存转移',
    SelectPeriod: '选择时期',
    ThisYear: '今年',
    ThisToday: '今天',
    ThisMonth: '这个月',
    ThisWeek: '本星期',
    AdjustmentDetail: '调整细节',
    ActivateUser: '该用户已被激活',
    DisActivateUser: '此用户已被停用',
    NotFound: '找不到网页。',
    oops: '错误！ 找不到网页。',
    couldNotFind: '我们找不到您想要的页面。与此同时，您可能',
    ReturnDashboard: '返回仪表板',


     //hrm module
     hrm:'人力资源管理',
     Employees:'雇员',
     Attendance:'出勤率',
     Leave_request:'离开请求',
     Leave_type:'休假类型',
     Company:'公司',
     Departments:'部门',
     Designations:'名称',
     Office_Shift:'办公室班次',
     Holidays:'节假日',
     Enter_Company_Name:'输入公司名称',
     Enter_email_address:'输入电邮地址',
     Enter_Company_Phone:'输入公司电话',
     Enter_Company_Country:'输入公司国家',
     Created_in_successfully:'创建成功',
     Updated_in_successfully:'更新成功',
     Deleted_in_successfully:'已成功删除',
     department:'部',
     Enter_Department_Name:'输入部门名称',
     Choose_Company:'选择公司',
     Department_Head:'部门负责人',
     Choose_Department_Head:'选择部门负责人',
     Enter_Shift_name:'输入班次名称',
     Monday_In:'Monday In',
     Monday_Out:'Monday Out',
     Tuesday_In:'Tuesday In',
     tuesday_out:'tuesday Out',
     wednesday_in:'Wednesday In',
     wednesday_out:'Wednesday Out',
     thursday_in:'Thursday In',
     thursday_out:'Thursday Out',
     friday_in:'Friday In',
     friday_out:'Friday Out',
     saturday_in:'Saturday In',
     saturday_out:'Saturday Out',
     sunday_in:'Sunday In',
     sunday_out:'Sunday Out',
     Holiday:'假期',
     Enter_title:'输入标题',
     title:'标题',
     start_date:'开始日期',
     Enter_Start_date:'输入开始日期',
     Finish_Date:'结束时间',
     Enter_Finish_date:'请输入结束日期',
     Please_provide_any_details:'请提供任何详细信息',
     Attendances:'出勤率',
     Enter_Attendance_date:'输入出席日期',
     Time_In:'Time In',
     Time_Out:'Time Out',
     Choose_Employee:'选择员工',
     Employee:'雇员',
     Work_Duration:'工作时间',
     remaining_leaves_are_insufficient:'剩余的叶子不足',
     Leave_Type:'休假类型',
     Days:'天数',
     Department:'部',
     Choose_leave_type:'选择休假类型',
     Choose_status:'地位',
     Leave_Reason:'离职原因',
     Enter_Reason_Leave:'输入原因请假',
     Add_Employee:'添加员工',
     FirstName:'名',
     Enter_FirstName:'输入名字',
     LastName:'姓氏',
     Enter_LastName:'输入姓氏',
     Gender:'性别',
     Choose_Gender:'选择性别',
     Enter_Birth_date:'输入出生日期',
     Birth_date:'出生日期',
     Enter_Country:'进入国家/地区',
     Enter_Phone_Number:'输入电话号码',
     joining_date:'加盟日期',
     Enter_joining_date:'输入入会日期',
     Choose_Designation:'选择指定',
     Designation:'指定',
     Office_Shift:'办公室班次',
     Choose_Office_Shift:'选择办公室班次',
     Enter_Leaving_Date:'输入离开日期',
     Leaving_Date:'离开日期',
     Annual_Leave:'年假',
     Enter_Annual_Leave:'输入年假',
     Remaining_leave:'剩余假期',
     Employee_Details:'员工详情',
     Basic_Information:'基本信息',
     Family_status:'家庭状况',
     Choose_Family_status:'选择家庭状态',
     Employment_type:'雇佣类型',
     Select_Employment_type:'选择就业类型',
     Enter_City:'进入城市',
     Province:'省份',
     Enter_Province:'输入省份',
     Enter_Address:'输入地址',
     Enter_Zip_code:'输入邮政编号',
     Zip_code:'邮政编码',
     Hourly_rate:'每小时费率',
     Enter_Hourly_rate:'输入每小时费率',
     Basic_salary:'基础工资',
     Enter_Basic_salary:'输入基本工资',
     Social_Media:'社交媒体',
     Skype:'Skype',
     Enter_Skype:'进入 Skype',
     Facebook:'Facebook',
     Enter_Facebook:'进入 Facebook',
     WhatsApp:'WhatsApp',
     Enter_WhatsApp:'进入 WhatsApp',
     LinkedIn:'LinkedIn',
     Enter_LinkedIn:'进入 LinkedIn',
     Twitter:'Twitter',
     Enter_Twitter:'进入 Twitter',
     Experiences:'经验',
     bank_account:'银行户口',
     Company_Name:'公司名',
     Location:'位置',
     Enter_location:'输入地点',
     Enter_Description:'输入描述',
     Bank_Name:'银行名',
     Enter_Bank_Name:'输入银行名称',
     Bank_Branch:'银行支行',
     Enter_Bank_Branch:'进入银行分行',
     Bank_Number:'银行号码',
     Enter_Bank_Number:'输入银行号码',
     Assigned_warehouses:'指定仓库',
     Top_customers:'顶级客户',
     Attachment:'附件',
     view_employee:'查看员工',
     edit_employee:'编辑员工',
     delete_employee:'删除员工',
    Created_by : '添加者',
    Add_product_IMEI_Serial_number:'添加产品 IMEI/序列号',
    Product_Has_Imei_Serial_number:'产品有Imei/序列号',
    IMEI_SN:'IMEI/SN',
    Shipments:'出货量',
    delivered_to:'送到了（送去了',
    shipment_ref:'装运参考',
    sale_ref:'销售参考',
    Edit_Shipping:'编辑运输',
    Packed:'包装好的',
    Shipped:'已发货',
    Delivered:'发表',
    Cancelled:'取消',
    Shipping_status:'发货状态',
    Users_Report:'用户报告',
    stock_report:'库存报告',
    TotalPurchases:'总购买量',
    Total_quotations:'总报价',
    Total_return_sales:'退货总销售额',
    Total_return_purchases:'总退货购买',
    Total_transfers:'总转帐',
    Total_adjustments:'总调整',
    User_report:'用户报告',
    Current_stock:'当前库存',
    product_name:'产品名称',
    Total_Customers_Due:'总债务',
    Total_Suppliers_Due:'总债务',
    Some_warehouses:'一些仓库',
    All_Warehouses:'所有仓库',
    Product_Cost:'产品成本',
    sms_settings:'短信设置',
    pos_settings:'销售点设置',
    payment_gateway:'支付网关',
    mail_settings:'邮件设置',
    Nexmo_SMS:'Nexmo SMS',
    TWILIO_SMS:'TWILIO SMS',
    Default_SMS_Gateway:'默认短信网关',
    module_settings:'模块设置',
    Module_enabled_success:'模块启用成功',
    Module_Disabled_success:'模块禁用成功',
    update_settings:'更新设置',
    Please_Upload_the_Correct_Module:'请上传正确的模块',
    Uploaded_Success:'上传成功',
    Customer_details:'顾客信息',
    Edit_Customer:'编辑客户',
    Delete_Customer:'删除客户',
    Pay_Due:'支付所有应付款',
    Paying_amount_is_greater_than_Total_Due:'支付金额大于到期总额',
    Customer_Invoice:'客户发票',
    This_Product_Not_For_Selling:'本产品不卖',
    Welcome_to_your_Dashboard:'欢迎来到您的仪表板',
    Total_Payable:'应付总额',
    Choose_Sale_Ref:'选择销售参考',
    Please_Select_Sale:'请选择销售',
    Sale_Ref:'销售参考',
    list_product_returns:'列出产品退货',
    Qty_return:'数量退货',
    Choose_Purchase_Ref:'选择购买参考',
    Purchase_Ref:'购买参考',
    please_select_purchase:'请选择采购',
    qty_return_is_greater_than_qty_purchased:'退货数量大于购买数量',
    qty_return_is_greater_than_qty_sold:'退货数量大于售出数量',
    Quantity_sold:'售出数量',
    qty_purchased:'购买数量',
    Sender_Name:'发件者姓名',
    The_module_must_be_uploaded_as_zip_file:'模块必须上传为 zip 文件',
    Upload_Module:'上传模块',
    All_Modules_Installed:'已安装的所有模块',
    Current_Version:'当前版本',
    You_already_have_the_latest_version:'您已经拥有最新版本',
    Update_Available:'更新可用',
    Update_Log:'升级',
    Update_Now:'现在更新',
    View_Change_Log:'查看更改日志',
    invoice_footer:'发票页脚',
    Please_Wait_for_the_update :'注意：请等待更新完全完成。 不建议关闭浏览器或停止进程升级',
    Note_update :'注意：确保在运行升级之前备份您的当前版本和数据库，如果出现错误，请恢复它',
    Time_Zone:'时区',
    Payment_note:'备注付款',
    sale_note:'销售说明',
    Total_Sale_Due:'总销售债务',
    Total_Sell_Return_Due:'总销售回报债务',
    pay_all_sell_due_at_a_time:'一次偿还所有出售债务',
    pay_all_sell_return_due_at_a_time:'一次支付所有的卖出回报债务',
    Gross_Profit:'毛利',
    pay_all_purchase_due_at_a_time:'一次支付所有购买债务',
    Delete_Provider:'删除提供者',
    Edit_Provider:'编辑提供者',
    Provider_details:'提供者详细信息',
    Total_Purchase_Due:'购买债务总额',
    Provider_Credit_Note:'贷方通知单',
    Customer_Credit_Note:'贷方通知单',
    Sell_Return:'卖出回报',
    Purchase_Return:'购买退货',
    Tax_Number:'税务号码',
    Please_add_return_quantity:'请添加退货数量',
    Return_exist_for_the_Transaction:'交易存在退货',
    qty_return_is_greater_than_Quantity_Remaining:'数量回报大于剩余数量',
    products_refunded_alert:'任何数量设置为 0 的产品将不予退款',
    pay_all_purchase_return_due_at_a_time:'一次支付所有购买回报债务',
    Total_Purchase_Return_Due:'总采购退货到期',
    Purchase_return_due:'购买回报债务',
    Sell_return_due:'出售回报债务',
    product_report:'产品报告',
    product_sales_report:'产品销售报告',
    Product_purchases_report:'产品采购报告',
    Qty_sold:'售出数量',
    Qty_purchased:'购买数量',
    Filter_by_warehouse:'按仓库筛选',
    Enable_Print_Invoice:'自动打印发票',
    Show_Warehouse:'查看仓库',

    credit_card_info :'信用卡信息',
    Saved_Credit_Card_Info:'已保存的信用卡信息',
    Credit_card_changed_successfully:'信用卡更改成功',
    notification_template:'通知模板',
    email_notification:'电子邮件通知',
    sms_notification:'短信通知',
    Change_product_details:'更改产品详细信息',
    edit_tax_and_discount_and_shipping :'编辑税收、折扣和运费',
    Create_Quotation_with_Stock:'使用库存创建报价',
    Variant_Name:'变体名称',
    Variant_cost:'变体成本',
    Variant_price:'变体价格',
    Variant_code:'变体代码',
    Please_wait_until_the_product_is_loaded:'请等待产品加载完成',
    Choose_SMS_Gateway:'选择短信网关',
    Notification_Client:'客户通知',
    Available_Tags:'可用标签',
    Email_Subject:'电子邮件主题',
    Email_body:'电子邮件正文',
    Notification_Supplier:'供应商通知',
    sms_body:'短信正文',
    sms_templates:'SMS Templates',
    email_templates:'Email Templates',
};